import { moduleType } from "./../interfaces/moduleInterfaces";
import axios from "axios";
import { getToken } from "../utils/localStorage";
import { CURRENT_WEB_VERSION } from "../utils/constant/general";
import { message_update_version } from "./../utils/constant/general";

const { REACT_APP_API_URL } = process.env;

export interface Results {
	results: Result[];
}
export interface Result {}

type apiConfigType = {
	method: "post" | "get" | "delete" | "put";
	request_name: string;
	api_path: string;
	data?: Record<string, any>;
	params?: Record<string, any>;
	baseURL?: string;
	module?: moduleType;
	version?: string;
	headers?: any;
};

const updateVersion = async () => {
	return await axios({
		method: "get",
		url: REACT_APP_API_URL + "apps_version",
	}).then((res): "canceled" | "updated" | "accepted" => {
		const { LATEST_WEB_VERSION } = res.data;
		let result: any = "updated";
		if (LATEST_WEB_VERSION !== CURRENT_WEB_VERSION) {
			window.confirm(
				message_update_version +
					`\nYour version : ${CURRENT_WEB_VERSION} Updated version : ${LATEST_WEB_VERSION} `
			)
				? (result = "accepted")
				: (result = "canceled");
			result === "accepted" && window.location.reload();
		}
		return result;
	});
};

const apiConfig = async ({
	method,
	request_name,
	api_path,
	data,
	params,
	baseURL,
	module = "rms",
	version = "V1",
	headers,
}: apiConfigType) => {
	let result_data: object = {};
	const token = await getToken().then((tokenObject) => tokenObject.token);
	const instance = axios.create({
		baseURL:
			module === "rms"
				? process.env[`REACT_APP_API_URL_${version}`]
				: process.env[`REACT_APP_API_URL_${module.toUpperCase()}_${version}`],
		timeout: 120000,
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
	// if ((await updateVersion()) === "updated") {
	// 	await instance({
	// 		baseURL,
	// 		method,
	// 		url: api_path,
	// 		data,
	// 		params,
	// 	})
	// 		.then((res) => {
	// 			result_data = res.data;
	// 		})
	// 		.catch((err) => {
	// 			if (process.env.NODE_ENV === "development") {
	// 				console.log(`${err}, request ${request_name} error`);
	// 			} else {
	// 				console.log(`request ${request_name} error`);
	// 			}
	// 		});
	// 	return result_data;
	// }

	await instance({
		baseURL,
		method,
		url: api_path,
		data,
		params,
	})
		.then((res) => {
			result_data = res.data;
		})
		.catch((err) => {
			if (process.env.NODE_ENV === "development") {
				console.log(`${err}, request ${request_name} error`);
			} else {
				console.log(`request ${request_name} error`);
			}
		});
	return result_data;
};

export default apiConfig;
