import React, { createContext, useEffect, useState } from "react";
import { accessListsType } from "../../interfaces/access_lists_interfaces";

import { Icons } from "../../themes/icons";
import { urlPath } from "../../utils/constant/urlPath";
import { getToken } from "../../utils/localStorage";

const {
	dashboard_icon,
	event_icon,
	calendar_white,
	lost_found_icon,
	package_icon,
	reminder_icon,
	recurring_icon,
	reports_icon,
	location_icon,
	assets_management_icon,
	workorder_icon,
} = Icons;

const menus_init: menuType[] = [
	{
		main_menu: {
			name: urlPath.Dashboard.Root.name,
			path: urlPath.Dashboard.Root.path,
			icon: dashboard_icon,
		},
		sub_menu: [],
	},
	{
		main_menu: {
			name: urlPath.Events.Root.name,
			path: urlPath.Events.Root.path,
			icon: event_icon,
		},
		sub_menu: [
			{
				name: urlPath.Events.EventLists.name,
				path: urlPath.Events.EventLists.path,
				icon: calendar_white,
			},
			{
				name: urlPath.EventScheduled.Root.name,
				path: urlPath.EventScheduled.Root.path,
				icon: recurring_icon,
			},
		],
	},
	{
		main_menu: {
			name: urlPath.LocationOverview.Root.name,
			path: urlPath.LocationOverview.Root.path,
			icon: location_icon,
		},
		sub_menu: [
			{
				name: urlPath.LocationOverview.LocationOverviewAsset.menu_name,
				path: urlPath.LocationOverview.LocationOverviewAsset.path,
				icon: workorder_icon,
			},
			{
				name: urlPath.LocationOverview.LocationOverviewActivity.menu_name,
				path: urlPath.LocationOverview.LocationOverviewActivity.path,
				icon: calendar_white,
			},
		],
	},
	{
		main_menu: {
			name: urlPath.LostAndFound.Root.name,
			path: urlPath.LostAndFound.Root.path,
			icon: lost_found_icon,
		},
		sub_menu: [],
	},
	{
		main_menu: {
			name: urlPath.Package.Root.name,
			path: urlPath.Package.Root.path,
			icon: package_icon,
		},
		sub_menu: [],
	},
	{
		main_menu: {
			name: urlPath.Reminder.Root.name,
			path: urlPath.Reminder.Root.path,
			icon: reminder_icon,
		},
		sub_menu: [],
	},
	{
		main_menu: {
			name: urlPath.AssetManagement.Root.name,
			path: urlPath.AssetManagement.Root.path,
			icon: assets_management_icon,
		},
		sub_menu: [],
	},
	{
		main_menu: {
			name: urlPath.Workorder.Root.name,
			path: urlPath.Workorder.Root.path,
			icon: workorder_icon,
		},
		sub_menu: [
			{
				name: urlPath.Workorder.Corrective.name,
				path: urlPath.Workorder.Corrective.path,
				icon: calendar_white,
			},
			{
				name: urlPath.Workorder.Preventive.name,
				path: urlPath.Workorder.Preventive.path,
				icon: recurring_icon,
			},
			{
				name: urlPath.Workorder.PreventiveScheduling.name,
				path: urlPath.Workorder.PreventiveScheduling.path,
				icon: recurring_icon,
			},
		],
	},
	{
		main_menu: {
			name: urlPath.Reports.Root.name,
			path: urlPath.Reports.Root.path,
			icon: reports_icon,
		},
		sub_menu: [],
	},
];

type menuType = {
	main_menu: {
		name: string;
		path: string;
		icon: string;
	};
	sub_menu: { name: string; path: string; icon: string }[];
};
interface MenuSidebarNode {
	menus: menuType[];
	show_submenu: number;
	setShowSubmenu: Function;
}
const MenuSidebarContext = createContext({} as MenuSidebarNode);

interface Props {}
const MenuSidebarProvider: React.FC<Props> = ({ children }) => {
	const [show_submenu, setShowSubmenu] = useState(0);
	const [menus, setMenus] = useState(menus_init);

	/* Access Lists */
	const [access_lists, setAccessLists] = useState<accessListsType>();
	useEffect(() => {
		getToken().then((payload: any) => {
			const token_access_lists = payload.data.access_lists;
			const lists_parsed = JSON.parse(token_access_lists);
			setAccessLists(lists_parsed);
		});
		return () => {};
	}, []);

	// access lists
	useEffect(() => {
		let menu_copies: menuType[] = [...menus_init];
		if (access_lists) {
			// if (!access_lists?.ams?.workorder?.is_active) {
			// 	menu_copies[7].sub_menu = menu_copies[7].sub_menu.filter(
			// 		(sub_menu) => sub_menu.name !== urlPath.Workorder.Corrective.name
			// 	);
			// }
			// if (!access_lists?.ams?.workorder_preventive?.is_active) {
			// 	menu_copies[7].sub_menu = menu_copies[7].sub_menu.filter(
			// 		(sub_menu) =>
			// 			sub_menu.name !== urlPath.Workorder.Preventive.name &&
			// 			sub_menu.name !== urlPath.Workorder.PreventiveScheduling.name
			// 	);
			// }
			if (
				!access_lists?.rms?.location_overview?.is_active &&
				!access_lists?.ams?.location_overview?.is_active
			) {
				menu_copies = menu_copies.filter(
					(menu_copy) =>
						menu_copy.main_menu.name !== urlPath.LocationOverview.Root.name
				);
			}
			if (!access_lists?.ams?.location_overview?.is_active) {
				menu_copies[2].sub_menu = menu_copies[2].sub_menu.filter(
					(sub_menu) =>
						sub_menu.name !==
						urlPath.LocationOverview.LocationOverviewAsset.menu_name
				);
			}
			if (!access_lists?.rms?.event?.is_active) {
				menu_copies[1].sub_menu = menu_copies[1].sub_menu.filter(
					(sub_menu) => sub_menu.name !== urlPath.Events.EventLists.name
				);
			}
			if (!access_lists?.rms?.event_scheduled?.is_active) {
				menu_copies[1].sub_menu = menu_copies[1].sub_menu.filter(
					(sub_menu) => sub_menu.name !== urlPath.EventScheduled.Root.name
				);
			}
			if (
				!access_lists?.rms?.event?.is_active &&
				!access_lists?.rms?.event_scheduled?.is_active
			) {
				menu_copies = menu_copies.filter(
					(menu_copy) => menu_copy.main_menu.name !== urlPath.Events.Root.name
				);
			}
			if (!access_lists?.rms?.lost_and_found?.is_active) {
				menu_copies = menu_copies.filter(
					(menu_copy) =>
						menu_copy.main_menu.name !== urlPath.LostAndFound.Root.name
				);
			}
			if (!access_lists?.rms?.packages?.is_active) {
				menu_copies = menu_copies.filter(
					(menu_copy) => menu_copy.main_menu.name !== urlPath.Package.Root.name
				);
			}
			if (!access_lists?.rms?.reminder?.is_active) {
				menu_copies = menu_copies.filter(
					(menu_copy) => menu_copy.main_menu.name !== urlPath.Reminder.Root.name
				);
			}
			if (
				!access_lists?.rms?.report?.is_active &&
				!access_lists?.ams?.report?.is_active
			) {
				menu_copies = menu_copies.filter(
					(menu_copy) => menu_copy.main_menu.name !== urlPath.Reports.Root.name
				);
			}
			if (
				!access_lists?.rms?.dashboard?.is_active &&
				!access_lists?.ams?.dashboard?.is_active
			) {
				menu_copies = menu_copies.filter(
					(menu_copy) =>
						menu_copy.main_menu.name !== urlPath.Dashboard.Root.name
				);
			}
			if (
				!access_lists?.ams?.workorder?.is_active &&
				!access_lists?.ams?.workorder_preventive?.is_active
			) {
				menu_copies = menu_copies.filter(
					(menu_copy) =>
						menu_copy.main_menu.name !== urlPath.Workorder.Root.name
				);
			}
			if (!access_lists?.ams?.setting?.is_active) {
				menu_copies = menu_copies.filter(
					(menu_copy) =>
						menu_copy.main_menu.name !== urlPath.AssetManagement.Root.name
				);
			}
		}
		setMenus(menu_copies);
	}, [access_lists]);

	return (
		<MenuSidebarContext.Provider
			value={{
				menus,
				show_submenu,
				setShowSubmenu,
			}}
		>
			{children}
		</MenuSidebarContext.Provider>
	);
};

export { MenuSidebarContext, MenuSidebarProvider };
