import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import MainContainer from "./containers/Main";
import { PrivacyPolicy } from "./containers/PrivacyPolicy";
import Loader from "./components/Global/Loader/";
import { getToken } from "./utils/localStorage";
import { isTokenExpired } from "./utils/helper";
import { Alert } from "./components/Global/Alert";
import { ReminderPopUp } from "./components/Reminder/PopUp";

import { AlertProvider } from "./contexts/Alert/AlertContext";
import { FirebaseProvider } from "./contexts/FirebaseContext";
import { AuthProvider } from "./contexts/Auth/AuthContext";
import { PaginationProvider } from "./contexts/PaginationContext";
import { ReminderProvider } from "./contexts/Reminder/ReminderContext";
import { FilterProvider } from "./contexts/FilterContext";

import routes from "./routes";
import { ModalUpdateVersion } from "./components/Global/ModalUpdateVersion";

const Login: React.FC<{}> = () => {
	const Component = lazy(() => import("./containers/Login"));
	return (
		<Suspense fallback={<Loader />}>
			<Component />
		</Suspense>
	);
};

interface Props {
	exact: boolean;
	component: any;
	path: string;
	name: string;
	props: any;
}
const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
	const history = useHistory();
	getToken().then(async (token_object) => {
		const is_expired = await isTokenExpired();
		const token = token_object.token;
		if (!token || is_expired) history.push("/login");
	});
	return (
		<Route
			{...rest}
			render={(props: any) => {
				return (
					<MainContainer>
						<PaginationProvider>
							<FilterProvider>
								<ReminderProvider>
									<ReminderPopUp />
									<Component {...props} />
								</ReminderProvider>
							</FilterProvider>
						</PaginationProvider>
					</MainContainer>
				);
			}}
		/>
	);
};

const App = (props: any) => {
	return (
		<BrowserRouter>
			<FirebaseProvider>
				<AlertProvider>
					<AuthProvider>
						<Alert />
						<ModalUpdateVersion />
						<Switch>
							<Route
								path="/privacy-policy"
								component={() => <PrivacyPolicy />}
							/>
							<Route path="/login" component={() => <Login />} />
							{routes.map((route, key) => {
								return (
									route.component && (
										<PrivateRoute
											key={key}
											exact={true}
											name={route.name}
											path={route.path}
											component={route.component}
											props={props}
										/>
									)
								);
							})}
							<Route component={() => <h1>Page not Found</h1>} />
						</Switch>
					</AuthProvider>
				</AlertProvider>
			</FirebaseProvider>
		</BrowserRouter>
	);
};

export default Sentry.withProfiler(App);
