import search_icon from "../assets/icons/search.svg";
import notification_icon from "../assets/icons/notification_bell.svg";
import notification_icon_purple from "../assets/icons/notification_icon_purple.svg";
import dropdown_arrow_icon from "../assets/icons/dropdown_arrow.svg";
import dropdown_arrow_grey_icon from "../assets/icons/dropdown_arrow_grey.svg";
import dropdown_arrow_blue_icon from "../assets/icons/dropdown_arrow_blue.svg";
import dropdown_arrow_right_icon from "../assets/icons/dropdown_arrow_right.svg";
import arrow_left_circle_icon from "../assets/icons/arrow_left_circle.svg";
import arrow_right_circle_icon from "../assets/icons/arrow_right_circle.svg";
import arrow_left_grey from "../assets/icons/arrow_left.svg";
import arrow_right_grey from "../assets/icons/arrow_right.svg";
import user_icon_default from "../assets/icons/user_icon_default.svg";
import calendar_icon from "../assets/icons/calendar.svg";
import calendar_white from "../assets/icons/calendar_white.svg";
import filter_icon from "../assets/icons/filter.svg";
import filter_icon_purple from "../assets/icons/filter_icon_purple.svg";
import eye_icon from "../assets/icons/eye.svg";
import eye_not from "../assets/icons/eye_not.svg";

import three_dot_action from "../assets/icons/three_dot_action.svg";
import close_icon from "../assets/icons/close_icon.svg";
import close_icon_white from "../assets/icons/close_icon2.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import sort_icon_purple from "../assets/icons/swap_vertical_icon_purple.svg";
import logout from "../assets/icons/logout.svg";
import upload_cloud from "../assets/icons/upload_cloud.svg";
import circle_check_blue from "../assets/icons/circle_check_blue.svg";
import circle_check_grey from "../assets/icons/circle_check_grey.svg";
import circle_check_white_green from "../assets/icons/circle_check_white_green.svg";
import circle_close_white_red from "../assets/icons/circle_close_white_red.svg";
import reject_icon from "../assets/icons/reject_icon.svg";
import unlock_grey_icon from "../assets/icons/unlock_grey_icon.svg";

import logo_icon from "../assets/icons/logo_icon.svg";

// menu
import dashboard_icon from "../assets/icons/menu_icons/dashboard.svg";
import event_icon from "../assets/icons/menu_icons/event.svg";
import housekeeping_icon from "../assets/icons/menu_icons/housekeeping.svg";
import lost_found_icon from "../assets/icons/menu_icons/lost_found.svg";
import package_icon from "../assets/icons/menu_icons/package.svg";
import reminder_icon from "../assets/icons/menu_icons/reminder_icon.svg";
import recurring_icon from "../assets/icons/menu_icons/recurring.svg";
import floating_menu_icon from "../assets/icons/menu_icons/floating_menu_icon.svg";
import floating_menu_grey_icon from "../assets/icons/menu_icons/floating_menu_grey_icon.svg";
import floating_menu_close from "../assets/icons/menu_icons/floating_menu_close.svg";
import reports_icon from "../assets/icons/menu_icons/reports_icon.svg";
import reports_event_icon from "../assets/icons/menu_icons/reports_event_icon.svg";
import reports_tag_icon from "../assets/icons/menu_icons/reports_tag_icon.svg";
import reports_employee_icon from "../assets/icons/menu_icons/reports_employee_icon.svg";
import assets_management_icon from "../assets/icons/menu_icons/asset_management_icon.svg";
import workorder_icon from "../assets/icons/menu_icons/workorder_icon.svg";

//
import add_icon from "../assets/icons/add_icon.svg";
import filter2_icon from "../assets/icons/filter2.svg";
import comment_buble from "../assets/icons/comment_buble.svg";
import comment_buble_pop from "../assets/icons/comment_buble_pop.svg";
import assigned_icon from "../assets/icons/assigned_icon.svg";
import not_assigned_icon from "../assets/icons/not_assigned_icon.svg";
import clock_icon from "../assets/icons/clock_icon.svg";
import clock_icon_green from "../assets/icons/clock_icon_green.svg";
import clock_icon_purple from "../assets/icons/clock_icon_purple.svg";
import check_green from "../assets/icons/check_green.svg";
import send_icon from "../assets/icons/send_icon.svg";
import gallery_icon from "../assets/icons/gallery_icon.svg";
import silver_dot from "../assets/icons/silver_dot.svg";
import circle_check_blue_fill from "../assets/icons/circle_check_blue_fill.svg";
import circle_check_grey_fill from "../assets/icons/circle_check_grey_fill.svg";

import empty_box from "../assets/icons/empty_box.svg";
import tag_icon from "../assets/icons/tag_icon.svg";
import circle_add_blue_fill from "../assets/icons/circle_add_blue_fill.svg";
import circle_add_grey_fill from "../assets/icons/circle_add_grey_fill.svg";
import circle_add_green_fill from "../assets/icons/circle_add_green_fill.svg";
import edit_icon from "../assets/icons/edit_icon.svg";
import edit_icon_black from "../assets/icons/edit_icon_black.svg";
import delete_icon from "../assets/icons/delete_icon.svg";
import delete_icon_purple from "../assets/icons/delete_icon_purple.svg";
import users_icon_grey from "../assets/icons/users_icon_grey.svg";
import user_icon_warning_red from "../assets/icons/user_icon_warning_red.svg";
import checklist_icon from "../assets/icons/checklist_icon.svg";
import bubble_chat_grey from "../assets/icons/bubble_chat_grey.svg";
import bubble_icon_warning_red from "../assets/icons/bubble_icon_warning_red.svg";

import circle_play_icon_green from "../assets/icons/circle_play_icon_green.svg";
import circle_alert from "../assets/icons/circle_alert.svg";
import circle_check_green from "../assets/icons/circle_check_green.svg";
import circle_clock_icon_green from "../assets/icons/circle_clock_icon_green.svg";
import event_complete_icon from "../assets/icons/event_complete_icon.svg";
import event_uncomplete_icon from "../assets/icons/event_uncomplete_icon.svg";
import download_icon from "../assets/icons/download_icon.svg";
import download_icon_green from "../assets/icons/download_icon_green.svg";
import download_icon_blue from "../assets/icons/download_icon_blue.svg";
import pen_edit_icon from "../assets/icons/pen_edit_icon.svg";
import notification_red from "../assets/icons/notification_red.svg";
import notification_bell_red from "../assets/icons/notification_bell_red.svg";
import trash_icon from "../assets/icons/trash_icon.svg";
import trash_icon_blue from "../assets/icons/trash_icon_blue.svg";
import trash_icon_purple from "../assets/icons/trash_icon_purple.svg";
import warning_red from "../assets/icons/warning_red.svg";
import reports_event_blue_icon from "../assets/icons/menu_icons/reports_event_blue_icon.svg";
import reports_tag_blue_icon from "../assets/icons/menu_icons/reports_tag_blue_icon.svg";
import reports_employee_blue_icon from "../assets/icons/menu_icons/reports_employee_blue_icon.svg";
import reports_lost_icon from "../assets/icons/menu_icons/report_lost_icon.svg";
import reports_package_icon from "../assets/icons/menu_icons/report_package_icon.svg";
import reports_pencil_icon from "../assets/icons/menu_icons/reports_pencil_icon.svg";
import reports_asset_icon from "../assets/icons/menu_icons/reports_asset_icon.svg";
import reports_expired_date_icon from "../assets/icons/menu_icons/reports_expired_date_icon.svg";
import reports_alarm_icon from "../assets/icons/menu_icons/reports_alarm_icon.svg";
import reports_location_icon from "../assets/icons/menu_icons/reports_location_icon.svg";

import location_icon from "../assets/icons/location_icon.svg";
import building_outline_icon from "../assets/icons/building_outline_icon.svg";

import gadget_icon from "../assets/icons/gadget_icon.svg";
import gadget_icon_white from "../assets/icons/gadget_icon_white.svg";
import group_icon from "../assets/icons/group_icon.svg";
import group_icon_white from "../assets/icons/group_icon_white.svg";
import config_icon from "../assets/icons/config_icon.svg";
import config_icon_white from "../assets/icons/config_icon_white.svg";
import notes_icon from "../assets/icons/notes_icon.svg";
import notes_icon_white from "../assets/icons/notes_icon_white.svg";
import plus_icon from "../assets/icons/plus_icon.svg";
import file_icon_purple from "../assets/icons/file_icon_purple.svg";
import circle_search_blue_fill from "../assets/icons/circle_search_blue_fill.svg";
import circle_document_blue_fill from "../assets/icons/circle_document_blue_fill.svg";

import user_check_transparent from "../assets/icons/user_check_transparent.svg";
import user_move_transparent from "../assets/icons/user_move_transparent.svg";
import clock_transparent from "../assets/icons/clock_transparent.svg";
import stopwatch_transparent from "../assets/icons/stopwatch_transparent.svg";
import sheet_checklist_transparent from "../assets/icons/sheet_checklist_transparent.svg";
import sheet_warning_transparent from "../assets/icons/sheet_warning_transparent.svg";
import plus_square from "../assets/icons/plus-square.svg";
import minus_square from "../assets/icons/minus-square.svg";
import in_repair_icon from "../assets/icons/in_repair.svg";
import broken_icon from "../assets/icons/broken.svg";
import retired_icon from "../assets/icons/retired.svg";

import expired_date_report_icon from "../assets/icons/expired_date_icon.svg";
import reminder_report_icon from "../assets/icons/reminder_report_icon.svg";
import history_report_icon from "../assets/icons/history_icon.svg";
import asset_report_icon from "../assets/icons/asset_report_icon.svg";
import shift_report_icon from "../assets/icons/shift_icon.svg";

import pdf_icon from "../assets/icons/pdf_icon.svg";
import close_linear_icon from "../assets/icons/close_linear_icon.svg";
import plus_linear_icon from "../assets/icons/plus_linear_icon.svg";
import check_linear_icon from "../assets/icons/check_linear_icon.svg";

export const Icons: Record<string, any> = {
  search_icon,
  notification_icon,
  notification_icon_purple,
  dropdown_arrow_icon,
  dropdown_arrow_grey_icon,
  dropdown_arrow_right_icon,
  arrow_left_circle_icon,
  arrow_right_circle_icon,
  user_icon_default,
  calendar_icon,
  calendar_white,
  filter_icon,
  filter_icon_purple,
  eye_icon,
  eye_not,

  three_dot_action,
  close_icon,
  close_icon_white,
  sort_icon,
  sort_icon_purple,
  logout,
  upload_cloud,
  circle_check_blue,
  circle_check_grey,
  circle_check_white_green,
  circle_close_white_red,

  reject_icon,
  clock_icon,
  clock_icon_green,
  clock_icon_purple,
  check_green,
  plus_icon,
  unlock_grey_icon,

  //menu
  dashboard_icon,
  event_icon,
  housekeeping_icon,
  lost_found_icon,
  package_icon,
  reminder_icon,
  recurring_icon,
  floating_menu_icon,
  floating_menu_grey_icon,
  floating_menu_close,
  reports_icon,
  reports_employee_icon,
  reports_event_icon,
  reports_tag_icon,
  assets_management_icon,
  workorder_icon,
  reports_pencil_icon,
  reports_asset_icon,
  reports_expired_date_icon,
  reports_alarm_icon,
  reports_location_icon,

  logo_icon,
  add_icon,
  filter2_icon,
  comment_buble,
  comment_buble_pop,
  assigned_icon,
  not_assigned_icon,
  send_icon,
  gallery_icon,
  silver_dot,
  circle_check_blue_fill,
  circle_check_grey_fill,

  empty_box,
  tag_icon,
  circle_add_blue_fill,
  circle_add_grey_fill,
  circle_add_green_fill,
  edit_icon,
  edit_icon_black,
  delete_icon,
  delete_icon_purple,
  users_icon_grey,
  user_icon_warning_red,
  bubble_icon_warning_red,
  checklist_icon,
  warning_red,
  reports_event_blue_icon,
  reports_tag_blue_icon,
  reports_employee_blue_icon,
  reports_lost_icon,
  reports_package_icon,
  bubble_chat_grey,

  circle_play_icon_green,
  circle_clock_icon_green,
  event_complete_icon,
  event_uncomplete_icon,
  download_icon,
  download_icon_green,
  download_icon_blue,
  pen_edit_icon,
  notification_red,
  notification_bell_red,
  trash_icon,
  trash_icon_blue,
  trash_icon_purple,
  file_icon_purple,

  location_icon,

  notes_icon,
  notes_icon_white,
  config_icon,
  config_icon_white,
  group_icon,
  group_icon_white,
  gadget_icon,
  gadget_icon_white,
  circle_search_blue_fill,
  circle_document_blue_fill,

  arrow_left_grey,
  arrow_right_grey,

  circle_check_green,
  circle_alert,

  sheet_checklist_transparent,
  sheet_warning_transparent,
  user_move_transparent,
  user_check_transparent,
  clock_transparent,
  stopwatch_transparent,
  plus_square,
  minus_square,

  in_repair_icon,
  broken_icon,
  retired_icon,

  dropdown_arrow_blue_icon,

  expired_date_report_icon,
  history_report_icon,
  asset_report_icon,
  reminder_report_icon,
  pdf_icon,
  building_outline_icon,
  shift_report_icon,

  close_linear_icon,
  plus_linear_icon,
  check_linear_icon,
};
