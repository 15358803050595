import { lazy, Suspense } from "react";

import Loader from "./components/Global/Loader";
import ErrorBoundary from "./components/Global/ErrorBoundary";

import { urlPath } from "./utils/constant/urlPath";
import { containerPath } from "./utils/constant/containerPath";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";

const Dashboard = () => {
	const { Dashboard } = containerPath;
	const Component = lazy(() => import(`./${Dashboard}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Event = () => {
	const { Event } = containerPath;
	const Component = lazy(() => import(`./${Event}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const EventDetail = () => {
	const { EventDetail } = containerPath;
	const Component = lazy(() => import(`./${EventDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const EventScheduled = () => {
	const { EventScheduled } = containerPath;
	const Component = lazy(() => import(`./${EventScheduled}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const EventScheduledDetail = () => {
	const { EventScheduledDetail } = containerPath;
	const Component = lazy(() => import(`/${EventScheduledDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const LocationOverview = () => {
	const { LocationOverview } = containerPath;
	const Component = lazy(() => import(`/${LocationOverview}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const LostAndFound = () => {
	const { LostAndFound } = containerPath;
	const Component = lazy(() => import(`/${LostAndFound}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Package = () => {
	const { Package } = containerPath;
	const Component = lazy(() => import(`/${Package}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Reminder = () => {
	const { Reminder } = containerPath;
	const Component = lazy(() => import(`/${Reminder}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AssetManagement = () => {
	const { AssetManagement } = containerPath;
	const Component = lazy(() => import(`/${AssetManagement}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsAssetConfiguration = () => {
	const { AmsAssetConfiguration } = containerPath;
	const Component = lazy(() => import(`/${AmsAssetConfiguration}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsVendor = () => {
	const { AmsVendor } = containerPath;
	const Component = lazy(() => import(`/${AmsVendor}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsVendorDetail = () => {
	const { AmsVendorDetail } = containerPath;
	const Component = lazy(() => import(`/${AmsVendorDetail}`));

	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsSparepart = () => {
	const { AmsSparepart } = containerPath;
	const Component = lazy(() => import(`/${AmsSparepart}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsSparepartDetail = () => {
	const { AmsSparepartDetail } = containerPath;
	const Component = lazy(() => import(`/${AmsSparepartDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsAssetList = () => {
	const { AmsAssetList } = containerPath;
	const Component = lazy(() => import(`./${AmsAssetList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const AmsCreateAsset = () => {
	const { AmsCreateAsset } = containerPath;
	const Component = lazy(() => import(`/${AmsCreateAsset}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

// AMS ITEMS
const AmsCreateItems = () => {
	const { AmsCreateItems } = containerPath;
	const Component = lazy(() => import(`/${AmsCreateItems}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

// WORKORDER START ////
const Corrective = () => {
	const { Corrective } = containerPath;
	const Component = lazy(() => import(`/${Corrective}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const WorkorderDetail = () => {
	const { WorkorderDetail } = containerPath;
	const Component = lazy(() => import(`/${WorkorderDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const Preventive = () => {
	const { Preventive } = containerPath;
	const Component = lazy(() => import(`/${Preventive}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const PreventiveSchedulingDetail = () => {
	const { PreventiveSchedulingDetail } = containerPath;
	const Component = lazy(() => import(`/${PreventiveSchedulingDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const PreventiveScheduling = () => {
	const { PreventiveScheduling } = containerPath;
	const Component = lazy(() => import(`/${PreventiveScheduling}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
// WORKORDER END ////

const Reports = () => {
	const { Reports } = containerPath;
	const Component = lazy(() => import(`/${Reports}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportsEventLocation = () => {
	const { ReportsEventLocation } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventLocation}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportsEventDetail = () => {
	const { ReportsEventDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsEventDetailPage = () => {
	const { ReportsEventDetailPage } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventDetailPage}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsEventEmployee = () => {
	const { ReportsEventEmployee } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventEmployee}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsEventStatus = () => {
	const { ReportsEventStatus } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventStatus}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsEventTag = () => {
	const { ReportsEventTag } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventTag}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsTagLocation = () => {
	const { ReportsTagLocation } = containerPath;
	const Component = lazy(() => import(`/${ReportsTagLocation}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsTagSummary = () => {
	const { ReportsTagSummary } = containerPath;
	const Component = lazy(() => import(`/${ReportsTagSummary}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsEmployee = () => {
	const { ReportsEmployee } = containerPath;
	const Component = lazy(() => import(`/${ReportsEmployee}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportsEmployeeDetail = () => {
	const { ReportsEmployeeDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportsEmployeeDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportsWorkorder = () => {
	const { ReportsWorkorder } = containerPath;
	const Component = lazy(() => import(`/${ReportsWorkorder}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsWorkorderDetail = () => {
	const { ReportsWorkorderDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportsWorkorderDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportsAssetList = () => {
	const { ReportsAssetList } = containerPath;
	const Component = lazy(() => import(`/${ReportsAssetList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportAssetDetail = () => {
	const { ReportsAssetDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportsAssetDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};
const ReportHistory = () => {
	const { ReportsHistory } = containerPath;
	const Component = lazy(() => import(`/${ReportsHistory}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportAmsTagList = () => {
	const { ReportAmsTagList } = containerPath;
	const Component = lazy(() => import(`/${ReportAmsTagList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportAmsTagSummary = () => {
	const { ReportAmsTagSummary } = containerPath;
	const Component = lazy(() => import(`/${ReportAmsTagSummary}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportRmsTagList = () => {
	const { ReportRmsTagList } = containerPath;
	const Component = lazy(() => import(`/${ReportRmsTagList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportRmsTagSummary = () => {
	const { ReportRmsTagSummary } = containerPath;
	const Component = lazy(() => import(`/${ReportRmsTagSummary}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportEventList = () => {
	const { ReportsEventList } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportEventDetail = () => {
	const { ReportsEventDetails } = containerPath;
	const Component = lazy(() => import(`/${ReportsEventDetails}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportPackageList = () => {
	const { ReportPackageList } = containerPath;
	const Component = lazy(() => import(`/${ReportPackageList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportPackageDetail = () => {
	const { ReportPackageDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportPackageDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportLostList = () => {
	const { ReportLostList } = containerPath;
	const Component = lazy(() => import(`/${ReportLostList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportLostDetail = () => {
	const { ReportLostDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportLostDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportFoundList = () => {
	const { ReportFoundList } = containerPath;
	const Component = lazy(() => import(`/${ReportFoundList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportFoundDetail = () => {
	const { ReportFoundDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportFoundDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportReminderList = () => {
	const { ReportReminderList } = containerPath;
	const Component = lazy(() => import(`/${ReportReminderList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportReminderDetail = () => {
	const { ReportReminderDetail } = containerPath;
	const Component = lazy(() => import(`/${ReportReminderDetail}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportSparepartList = () => {
	const { ReportSparepartList } = containerPath;
	const Component = lazy(() => import(`/${ReportSparepartList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportBASTHistory = () => {
	const { ReportBASTHistory } = containerPath;
	const Component = lazy(() => import(`/${ReportBASTHistory}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportExpiredDate = () => {
	const { ReportExpiredDate } = containerPath;
	const Component = lazy(() => import(`/${ReportExpiredDate}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportShiftList = () => {
	const { ReportShiftList } = containerPath;
	const Component = lazy(() => import(`/${ReportShiftList}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportEmployeeListAms = () => {
	const { ReportsEmployeeAms } = containerPath;
	const Component = lazy(() => import(`/${ReportsEmployeeAms}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const ReportEmployeeDetailAms = () => {
	const { ReportsEmployeeDetailAms } = containerPath;
	const Component = lazy(() => import(`/${ReportsEmployeeDetailAms}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const EventLocationOverview = () => {
	const { EventLocationOverview } = containerPath;
	const Component = lazy(() => import(`/${EventLocationOverview}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Profile = () => {
	const { Profile } = containerPath;
	const Component = lazy(() => import(`./${Profile}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Settings = () => {
	const { Settings } = containerPath;
	const Component = lazy(() => import(`./${Settings}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const SettingsTagCategoryAms = () => {
	const { SettingTagCategoryAms } = containerPath;
	const Component = lazy(() => import(`./${SettingTagCategoryAms}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const Notification = () => {
	const { Notification } = containerPath;
	const Component = lazy(() => import(`./${Notification}`));
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const EventTypeSelectTag = () => {
	const { EventTypeSelectTag } = containerPath;
	const Component = lazy(() => import(`./${EventTypeSelectTag}`));

	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const DetailClientSubscription = () => {
	const { DetailClientSubscription } = containerPath;
	const Component = lazy(() => import(`./${DetailClientSubscription}`));

	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<Component />
			</Suspense>
		</ErrorBoundary>
	);
};

const routes = [
	// dashboard
	{
		path: urlPath.Dashboard.Root.path,
		name: urlPath.Dashboard.Root.name,
		component: Dashboard,
	},
	// event
	{
		path: urlPath.Events.Root.path,
		name: urlPath.Events.Root.name,
		component: Event,
	},
	{
		path: urlPath.Events.EventLists.path,
		name: urlPath.Events.EventLists.name,
		component: Event,
	},
	{
		path: urlPath.Events.EventDetail.full_path,
		name: urlPath.Events.EventDetail.name,
		component: EventDetail,
	},
	// event scheduled
	{
		path: urlPath.EventScheduled.Root.path,
		name: urlPath.EventScheduled.Root.name,
		component: EventScheduled,
	},
	{
		path: urlPath.EventScheduled.EventScheduledDetail.full_path,
		name: urlPath.EventScheduled.EventScheduledDetail.name,
		component: EventScheduledDetail,
	},
	// event location overview
	{
		path: urlPath.EventLocationOverview.Root.path,
		name: urlPath.EventLocationOverview.Root.name,
		component: EventLocationOverview,
	},

	// Location Overview
	{
		path: urlPath.LocationOverview.LocationOverviewActivity.path,
		name: urlPath.LocationOverview.LocationOverviewActivity.name,
		component: LocationOverview,
	},
	{
		path: urlPath.LocationOverview.LocationOverviewAsset.path,
		name: urlPath.LocationOverview.LocationOverviewAsset.name,
		component: LocationOverview,
	},

	// Lost And Found
	{
		path: urlPath.LostAndFound.Root.path,
		name: urlPath.LostAndFound.Root.name,
		component: LostAndFound,
	},

	// Package
	{
		path: urlPath.Package.Root.path,
		name: urlPath.Package.Root.name,
		component: Package,
	},

	// Reminder
	{
		path: urlPath.Reminder.Root.path,
		name: urlPath.Reminder.Root.name,
		component: Reminder,
	},

	// AMS START //
	{
		path: urlPath.AssetManagement.Root.path,
		name: urlPath.AssetManagement.Root.name,
		component: AssetManagement,
	},
	{
		path: urlPath.AssetManagement.AssetConfiguration.path,
		name: urlPath.AssetManagement.AssetConfiguration.name,
		component: AmsAssetConfiguration,
	},
	{
		path: urlPath.AssetManagement.AssetManagementVendor.Root.path,
		name: urlPath.AssetManagement.AssetManagementVendor.Root.name,
		component: AmsVendor,
	},
	{
		path: urlPath.AssetManagement.AssetManagementVendor.VendorCreate.path,
		name: urlPath.AssetManagement.AssetManagementVendor.VendorCreate.name,
		component: AmsVendorDetail,
	},
	{
		path: urlPath.AssetManagement.AssetManagementVendor.VendorDetail.full_path,
		name: urlPath.AssetManagement.AssetManagementVendor.VendorDetail.name,
		component: AmsVendorDetail,
	},
	{
		path: urlPath.AssetManagement.AssetManagementPart.path,
		name: urlPath.AssetManagement.AssetManagementPart.name,
		component: AmsSparepart,
	},
	{
		path: urlPath.AssetManagement.AssetManagementPart.CreateSparepart.path,
		name: urlPath.AssetManagement.AssetManagementPart.CreateSparepart.name,
		component: AmsSparepartDetail,
	},
	{
		path: urlPath.AssetManagement.AssetManagementPart.SparepartDetail.full_path,
		name: urlPath.AssetManagement.AssetManagementPart.SparepartDetail.name,
		component: AmsSparepartDetail,
	},
	{
		path: urlPath.AssetManagement.AssetManagementList.Root.path,
		name: urlPath.AssetManagement.AssetManagementList.Root.name,
		component: AmsAssetList,
	},
	{
		path: urlPath.AssetManagement.AssetManagementList.CreateAsset.path,
		name: urlPath.AssetManagement.AssetManagementList.CreateAsset.name,
		component: AmsCreateAsset,
	},
	{
		path: urlPath.AssetManagement.AssetManagementList.DetailAsset.full_path,
		name: urlPath.AssetManagement.AssetManagementList.DetailAsset.name,
		component: AmsCreateAsset,
	},
	// Items
	{
		path: urlPath.AssetManagement.Items.CreateItems.full_path,
		name: urlPath.AssetManagement.Items.CreateItems.name,
		component: AmsCreateItems,
	},
	{
		path: urlPath.AssetManagement.Items.DetailItems.full_path,
		name: urlPath.AssetManagement.Items.DetailItems.name,
		component: AmsCreateItems,
	},
	//// WORKORDER
	{
		path: urlPath.Workorder.Corrective.path,
		name: urlPath.Workorder.Corrective.name,
		component: Corrective,
	},
	{
		path: urlPath.Workorder.WorkorderDetail.full_path,
		name: urlPath.Workorder.WorkorderDetail.name,
		component: WorkorderDetail,
	},
	{
		path: urlPath.Workorder.Preventive.path,
		name: urlPath.Workorder.Preventive.name,
		component: Preventive,
	},
	{
		path: urlPath.Workorder.PreventiveScheduling.path,
		name: urlPath.Workorder.PreventiveScheduling.name,
		component: PreventiveScheduling,
	},
	{
		path: urlPath.Workorder.PreventiveScheduling.DetailPreventiveScheduling
			.full_path,
		name: urlPath.Workorder.PreventiveScheduling.DetailPreventiveScheduling
			.name,
		component: PreventiveSchedulingDetail,
	},
	{
		path: urlPath.Workorder.PreventiveScheduling.CreatePreventiveScheduling
			.path,
		name: urlPath.Workorder.PreventiveScheduling.CreatePreventiveScheduling
			.name,
		component: PreventiveSchedulingDetail,
	},

	// Reports
	{
		path: urlPath.Reports.Root.path,
		name: urlPath.Reports.Root.name,
		component: Reports,
	},
	{
		path: urlPath.Reports.EventLocation.path,
		name: urlPath.Reports.EventLocation.name,
		component: ReportsEventLocation,
	},
	{
		path: urlPath.Reports.EventEmployee.path,
		name: urlPath.Reports.EventEmployee.name,
		component: ReportsEventEmployee,
	},
	{
		path: urlPath.Reports.EventStatus.path,
		name: urlPath.Reports.EventStatus.name,
		component: ReportsEventStatus,
	},
	{
		path: urlPath.Reports.EventTag.path,
		name: urlPath.Reports.EventTag.name,
		component: ReportsEventTag,
	},
	{
		path: urlPath.Reports.EventDetail.path,
		name: urlPath.Reports.EventDetail.name,
		component: ReportsEventDetail,
	},
	{
		path: urlPath.Reports.EventDetailPage.path,
		name: urlPath.Reports.EventDetailPage.name,
		component: ReportsEventDetailPage,
	},
	{
		path: urlPath.Reports.TagLocation.path,
		name: urlPath.Reports.TagLocation.name,
		component: ReportsTagLocation,
	},
	{
		path: urlPath.Reports.TagSummary.path,
		name: urlPath.Reports.TagSummary.name,
		component: ReportsTagSummary,
	},
	{
		path: urlPath.Reports.Employee.RMS.List.path,
		name: urlPath.Reports.Employee.RMS.List.name,
		component: ReportsEmployee,
	},
	{
		path: urlPath.Reports.Employee.RMS.Detail.full_path,
		name: urlPath.Reports.Employee.RMS.Detail.name,
		component: ReportsEmployeeDetail,
	},
	{
		path: urlPath.Reports.Employee.AMS.List.path,
		name: urlPath.Reports.Employee.AMS.List.name,
		component: ReportEmployeeListAms,
	},
	{
		path: urlPath.Reports.Employee.AMS.Detail.full_path,
		name: urlPath.Reports.Employee.AMS.Detail.name,
		component: ReportEmployeeDetailAms,
	},
	{
		path: urlPath.Reports.Workorder.Root.path,
		name: urlPath.Reports.Workorder.Root.name,
		component: ReportsWorkorder,
	},
	{
		path: urlPath.Reports.Workorder.Detail.full_path,
		name: urlPath.Reports.Workorder.Detail.name,
		component: ReportsWorkorderDetail,
	},
	{
		path: urlPath.Reports.AssetList.Root.path,
		name: urlPath.Reports.AssetList.Root.name,
		component: ReportsAssetList,
	},
	{
		path: urlPath.Reports.AssetList.Detail.full_path,
		name: urlPath.Reports.AssetList.Detail.name,
		component: ReportAssetDetail,
	},
	{
		path: urlPath.Reports.History.path,
		name: urlPath.Reports.History.name,
		component: ReportHistory,
	},
	{
		path: urlPath.Reports.TagAms.List.path,
		name: urlPath.Reports.TagAms.List.name,
		component: ReportAmsTagList,
	},
	{
		path: urlPath.Reports.TagAms.Summary.full_path,
		name: urlPath.Reports.TagAms.Summary.name,
		component: ReportAmsTagSummary,
	},
	{
		path: urlPath.Reports.TagRms.List.path,
		name: urlPath.Reports.TagRms.List.name,
		component: ReportRmsTagList,
	},
	{
		path: urlPath.Reports.TagRms.Summary.full_path,
		name: urlPath.Reports.TagRms.Summary.name,
		component: ReportRmsTagSummary,
	},
	{
		path: urlPath.Reports.Event.List.path,
		name: urlPath.Reports.Event.List.name,
		component: ReportEventList,
	},
	{
		path: urlPath.Reports.Event.Detail.full_path,
		name: urlPath.Reports.Event.Detail.name,
		component: ReportEventDetail,
	},
	{
		path: urlPath.Reports.Package.List.path,
		name: urlPath.Reports.Package.List.name,
		component: ReportPackageList,
	},
	{
		path: urlPath.Reports.Package.Detail.full_path,
		name: urlPath.Reports.Package.Detail.name,
		component: ReportPackageDetail,
	},
	{
		path: urlPath.Reports.Lost.List.path,
		name: urlPath.Reports.Lost.List.name,
		component: ReportLostList,
	},
	{
		path: urlPath.Reports.Lost.Detail.full_path,
		name: urlPath.Reports.Lost.Detail.name,
		component: ReportLostDetail,
	},
	{
		path: urlPath.Reports.Found.List.path,
		name: urlPath.Reports.Found.List.name,
		component: ReportFoundList,
	},
	{
		path: urlPath.Reports.Found.Detail.full_path,
		name: urlPath.Reports.Found.Detail.name,
		component: ReportFoundDetail,
	},
	{
		path: urlPath.Reports.Reminder.List.path,
		name: urlPath.Reports.Reminder.List.name,
		component: ReportReminderList,
	},
	{
		path: urlPath.Reports.Reminder.Detail.full_path,
		name: urlPath.Reports.Reminder.Detail.name,
		component: ReportReminderDetail,
	},
	{
		path: urlPath.Reports.Sparepart.List.path,
		name: urlPath.Reports.Sparepart.List.name,
		component: ReportSparepartList,
	},
	{
		path: urlPath.Reports.BASTHistory.List.path,
		name: urlPath.Reports.BASTHistory.List.name,
		component: ReportBASTHistory,
	},
	{
		path: urlPath.Reports.ExpiredDate.List.path,
		name: urlPath.Reports.ExpiredDate.List.name,
		component: ReportExpiredDate,
	},
	{
		path: urlPath.Reports.Shift.List.path,
		name: urlPath.Reports.Shift.List.name,
		component: ReportShiftList,
	},
	{
		path: urlPath.Reports.Shift.WorkorderDetail.full_path,
		name: urlPath.Reports.Shift.WorkorderDetail.name,
		component: ReportsWorkorderDetail,
	},
	{
		path: urlPath.Reports.Shift.EventDetail.full_path,
		name: urlPath.Reports.Shift.EventDetail.name,
		component: ReportEventDetail,
	},

	// Notification
	{
		path: urlPath.Notification.Root.path,
		name: urlPath.Notification.Root.name,
		component: Notification,
	},

	// Profile
	{
		path: urlPath.Profile.Root.path,
		name: urlPath.Profile.Root.name,
		component: Profile,
	},

	// setting
	{
		path: urlPath.Settings.Root.path,
		name: urlPath.Settings.Root.name,
		component: Settings,
	},

	// setting tag & category ams
	// create
	{
		path: urlPath.Settings.AmsTagCategory.Create.full_path,
		name: urlPath.Settings.AmsTagCategory.Create.name,
		component: SettingsTagCategoryAms,
	},
	{
		path: urlPath.Settings.AmsTagCategory.Detail.full_path,
		name: urlPath.Settings.AmsTagCategory.Detail.name,
		component: SettingsTagCategoryAms,
	},

	// Event type select tag
	{
		path: urlPath.Settings.TabPath.EventTypeSelectTag.path,
		name: urlPath.Settings.TabPath.EventTypeSelectTag.name,
		component: EventTypeSelectTag,
	},
	{
		path: urlPath.Settings.TabPath.ClientSubscription.Detail.full_path,
		name: urlPath.Settings.TabPath.ClientSubscription.Detail.name,
		component: DetailClientSubscription,
	},
];

export default routes;
