import { generateRandomString } from "./helper";
import { tokenObjectType } from "./../interfaces/token_interfaces";
import { encode, decode } from "../utils/helper";

const access_local_key: any = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY;
const access_token_key: any = process.env.REACT_APP_ACCESS_TOKEN_KEY;

export const getLocalData = () => {
	const local_data_encoded: any = localStorage.getItem(access_local_key);
	if (local_data_encoded) {
		const local_data_decoded: string = decode(local_data_encoded.substring(50));
		const local_data_parsed: object = JSON.parse(local_data_decoded);
		return local_data_parsed;
	} else {
		return {};
	}
};

export const setLocalData = (data: Record<string, any>) => {
	const rand = generateRandomString(50);
	const prev_local_data = getLocalData();
	const updated_local_data = Object.assign({ ...prev_local_data, ...data });
	const updated_local_data_string = JSON.stringify(updated_local_data);
	const updated_local_data_encoded = rand + encode(updated_local_data_string);
	localStorage.setItem(access_local_key, updated_local_data_encoded);
};

export const sidebar_preference = {
	setCollapsed: (is_sidebar_collapsed: boolean) => {
		setLocalData({ is_sidebar_collapsed });
	},
	isCollapsed: () => {
		const result: any = getLocalData();
		if (result) return result.is_sidebar_collapsed;
	},
};

export const storeToken = (token_object: tokenObjectType) => {
	const token_string = JSON.stringify(token_object);
	const rand = generateRandomString(100);
	const token_encode = rand + encode(token_string);
	localStorage.setItem(access_token_key, token_encode);
};
export const getToken = async (): Promise<tokenObjectType> => {
	const token: any = await localStorage.getItem(access_token_key);
	let token_object = {} as tokenObjectType;
	if (token) {
		const token_decode: any = await decode(token.substring(100));
		token_object =
			token_decode.length > 8 ? await JSON.parse(token_decode) : null;
	}
	return token_object;
};
export const removeToken = () => localStorage.removeItem(access_token_key);
export const removeLocalData = () => {
	const rand = generateRandomString(50);
	const { is_sidebar_collapsed }: any = getLocalData();
	const updated_local_data_string = JSON.stringify({ is_sidebar_collapsed });
	const updated_local_data_encoded = rand + encode(updated_local_data_string);

	localStorage.setItem(access_local_key, updated_local_data_encoded);
};
