import React from "react";

import LogoSidebar from "../../components/Sidebar/Logo/LogoSidebar";
import ListMenu from "../../components/Sidebar/ListMenu";
import ToggleButtonSidebar from "../../components/Sidebar/ToggleButton/ToggleButton";

import { MenuSidebarProvider } from "../../contexts/Menu/MenuSidebarContext";

import "./style.scss";

interface Props {
	is_collapse: boolean;
	setCollapse: Function;
}

export const Sidebar: React.FC<Props> = ({ is_collapse, setCollapse }) => {
	return (
		<MenuSidebarProvider>
			<div className={`sidebar-container ${is_collapse ? "collapsed" : ""}`}>
				<LogoSidebar is_collapse={is_collapse} />
				<ToggleButtonSidebar
					is_collapse={is_collapse}
					setCollapse={setCollapse}
				/>
				<ListMenu is_collapse={is_collapse} />
				{/* <DropdownMenuSidebar /> */}
				{/* <MenuSidebar /> */}
			</div>
		</MenuSidebarProvider>
	);
};

export default Sidebar;
