import {
	amsAccessTopicType,
	amsAccessViewType,
	amsAssetManagementActionType,
	amsWorkorderAccessActionType,
	amsWorkorderPreventiveAccessActionType,
	unionamsAccessValueType,
} from "./ams";
import {
	rmsAccessTopicType,
	rmsEventAccessActionType,
	rmsEventAccessViewType,
	rmsEventScheduledAccessActionType,
	rmsEventScheduledAccessViewType,
	unionRMSAccessValueType,
} from "./rms";

export type actionAccessType = "is_active" | "view" | "action";

export type accessListsType = {
	rms: {
		event: {
			is_active: boolean;
			view: Array<rmsEventAccessViewType>;
			action: Array<rmsEventAccessActionType>;
		};
		event_scheduled: {
			is_active: boolean;
			view: Array<rmsEventScheduledAccessViewType>;
			action: Array<rmsEventScheduledAccessActionType>;
		};
		location_overview: {
			is_active: boolean;
		};
		lost_and_found: {
			is_active: boolean;
		};
		packages: {
			is_active: boolean;
		};
		reminder: {
			is_active: boolean;
		};
		dashboard: {
			is_active: boolean;
		};
		report: {
			is_active: boolean;
		};
		setting: {
			is_active: boolean;
		};
		event_notification_department: {
			is_active: boolean;
		};
	};
	ams: {
		workorder: {
			is_active: boolean;
			view: Array<amsAccessViewType>;
			action: Array<amsWorkorderAccessActionType>;
		};
		workorder_preventive: {
			is_active: boolean;
			view: Array<amsAccessViewType>;
			action: Array<amsWorkorderPreventiveAccessActionType>;
		};
		location_overview: {
			is_active: boolean;
			view: Array<amsAccessViewType>;
		};
		dashboard: {
			is_active: boolean;
			view: Array<amsAccessViewType>;
		};
		setting: {
			is_active: boolean;
		};
		report: {
			is_active: boolean;
		};
		asset_management: {
			is_active: boolean;
			action: Array<amsAssetManagementActionType>;
		};
	};
	hms: Record<string, any>;
};

export const access_lists_super_admin: accessListsType = {
	ams: {
		workorder: {
			is_active: true,
			view: ["all_department", "user_department"],
			action: [
				"add_workorder",
				"assign_employee",
				"change_schedule",
				"complete_workorder",
				"pending_workorder",
				"uncomplete_workorder",
			],
		},
		workorder_preventive: {
			is_active: true,
			view: ["all_department", "user_department"],
			action: [
				"add_workorder",
				"assign_employee",
				"cancel_schedule",
				"change_schedule",
			],
		},
		location_overview: {
			is_active: true,
			view: ["all_department", "user_department"],
		},
		dashboard: {
			is_active: true,
			view: ["all_department", "user_department"],
		},
		setting: {
			is_active: true,
		},
		report: {
			is_active: true,
		},
		asset_management: {
			is_active: true,
			action: ["asset_configuration", "vendor", "sparepart", "asset_list"],
		},
	},
	hms: {},
	rms: {
		event: {
			view: ["all_department", "user_department"],
			action: [
				"add_event",
				"assign_employee",
				"complete_event",
				"pending_event",
				"uncomplete_event",
				"change_schedule",
			],
			is_active: true,
		},
		report: {
			is_active: true,
		},
		setting: {
			is_active: true,
		},
		event_notification_department: {
			is_active: true,
		},
		packages: {
			is_active: true,
		},
		reminder: {
			is_active: true,
		},
		dashboard: {
			is_active: true,
		},
		lost_and_found: {
			is_active: true,
		},
		event_scheduled: {
			view: ["all_department"],
			action: ["assign_employee", "change_schedule", "cancel_schedule"],
			is_active: true,
		},
		location_overview: {
			is_active: true,
		},
	},
};

export const access_lists_init: accessListsType = {
	rms: {
		event: {
			is_active: false,
			view: [],
			action: [],
		},
		event_scheduled: {
			is_active: false,
			view: [],
			action: [],
		},
		location_overview: {
			is_active: false,
		},
		lost_and_found: {
			is_active: false,
		},
		packages: {
			is_active: false,
		},
		reminder: {
			is_active: false,
		},
		dashboard: {
			is_active: false,
		},
		report: {
			is_active: false,
		},
		setting: {
			is_active: false,
		},
		event_notification_department: {
			is_active: false,
		},
	},
	ams: {
		workorder: {
			is_active: false,
			action: [],
			view: [],
		},
		workorder_preventive: {
			is_active: false,
			action: [],
			view: [],
		},
		location_overview: {
			is_active: false,
			view: [],
		},
		dashboard: {
			is_active: false,
			view: [],
		},
		setting: {
			is_active: false,
		},
		report: {
			is_active: false,
		},
		asset_management: {
			is_active: false,
			action: [],
		},
	},
	hms: {},
};

export type topicAccessType = rmsAccessTopicType | amsAccessTopicType;

export type unionAccessValueType =
	| unionRMSAccessValueType
	| unionamsAccessValueType;
