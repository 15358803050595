import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../../contexts/Notification/NotificationContext";

import { urlPath } from "../../../utils/constant/urlPath";
import { ListsAndOverview } from "../ListsAndOverview";

interface Props {
	is_show: any;
	setIsShow: Function;
}

export const NotificationOverview: React.FC<Props> = ({
	is_show,
	setIsShow,
}) => {
	const history = useHistory();

	const { notification_lists } = useContext(NotificationContext);

	return (
		<div
			className={`top-header-notification box-shadow 
			${is_show.notification ? "show" : "hide"}`}
			onMouseLeave={() => setIsShow({ ...is_show, notification: false })}
		>
			{notification_lists.length > 0 && notification_lists[0]?.id !== "" ? (
				<>
					<ListsAndOverview is_modal />
					<b
						className="show-flex center"
						onClick={() => history.push(urlPath.Notification.Root.path)}
					>
						See All Notification
					</b>
				</>
			) : (
				<div className={`show-flex centered ${is_show ? "show" : "hide"}`}>
					<p>There are no notification yet</p>
				</div>
			)}
		</div>
	);
};
