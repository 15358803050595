import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import * as html2Image from "html-to-image";
import { jsPDF } from "jspdf";

import { getToken } from "./localStorage";
import { anyObjectType } from "../interfaces/general_interface";

// copying array or object to avoid reference
export const copyingData = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const multiplyingData = (
  data_dummy: object | string,
  multiply_times: number
) => {
  let multipled: any = typeof data_dummy === "string" ? "" : [];
  for (let i = 0; i <= multiply_times; i++) {
    if (typeof data_dummy === "string") {
      multipled += data_dummy;
    } else {
      multipled.push(data_dummy);
    }
  }
  return multipled;
};

export const encode = (value: string) => {
  const stringTobase64 = btoa(value);
  return stringTobase64;
};
export const decode = (value: string) => {
  const base64ToString = atob(value);
  return base64ToString;
};

export const isTokenExpired = async () => {
  const token_object = await getToken().then((token_object) => token_object);
  const get_token = token_object.token;
  if (get_token && get_token !== undefined) {
    const { token, issued, expired } = token_object;
    const today = dayjs();
    const token_issued = dayjs(issued);
    const day_difference = today.diff(token_issued, "day", false);
    if (token === null || day_difference > Number(expired.replace("d", "")))
      return true;
    else return false;
  } else return true;
};

export const convertHexToRGBA = (hex: string, opacity: string = "1") => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
};

export const logFormData = (formData: FormData | any) => {
  console.log(Object.fromEntries(formData));
};

export const convertToMinutes = (
  time_type: "day" | "hour",
  value: number
): number => {
  let day_to_minutes = 0;
  let hour_to_minutes = 0;
  if (time_type === "day") {
    day_to_minutes = Math.floor(value * 1440); // 60*24
  }
  if (time_type === "hour") {
    hour_to_minutes = Math.floor(value * 60);
  }
  return day_to_minutes + hour_to_minutes;
};

export const convertFromMinutes = (minutes: number) => {
  const day = Math.floor(minutes / 1440); // 60*24
  const hour = Math.floor((minutes - day * 1440) / 60);
  const minute = Math.round(minutes % 60);

  return [day, hour, minute];
};

type timeFormType = { day: number; hour: number; minute: number };
export const arrayMinutesToTimeFormStructure = (
  array_of_minutes: number[]
): timeFormType[] => {
  const new_time_form: timeFormType[] = array_of_minutes.map(
    (minute: number, i: number) => {
      const times = convertFromMinutes(Number(minute));
      let time_obj = {
        day: Number(times[0]),
        hour: Number(times[1]),
        minute: Number(times[2]),
      };
      return time_obj;
    }
  );
  return new_time_form;
};

export const timeFormStructureToArrayMinutes = (
  times: timeFormType[]
): number[] => {
  const array_of_minutes: number[] = times.map((time) => {
    const resolve_time_minutes =
      convertToMinutes("day", time.day) +
      convertToMinutes("hour", time.hour) +
      time.minute;
    return resolve_time_minutes;
  });
  return array_of_minutes;
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getTimeDifference = (date: Date) => {
  const current_date = new Date();
  const created_date = new Date(date);

  const diff = dayjs(current_date).diff(created_date, "minute");

  const difference_time_minutes = current_date.getTimezoneOffset() + diff;
  return difference_time_minutes;
};

export const getDueTime = (time: number, time_passed: any) => {
  const due_time_minutes =
    time + new Date().getTimezoneOffset() - Number(time_passed);
  return due_time_minutes;
};

export const randomHexGenerator = () => {
  const random_color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${random_color}`;
};

export const firstLetterToCapital = (sentence: string) => {
  return sentence.replace(/\w\S*/g, (text) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
};

export const getEventStatusColor = (status_name: string) => {
  const _status_name = status_name.toLowerCase();
  return _status_name === "open"
    ? "#096fce"
    : _status_name === "ongoing"
    ? "#c2c2c8"
    : _status_name === "done"
    ? "#0450ee"
    : _status_name === "completed"
    ? "#1e0068"
    : _status_name === "pending"
    ? "#ce7f09"
    : _status_name === "overdue"
    ? "#6a0404"
    : _status_name === "overdue-resolve"
    ? "#6a0404"
    : _status_name === "overdue-response"
    ? "#6a0404"
    : "#ce0909"; // uncompleted
};

export const getNearest15Minutes = (): Date => {
  return new Date(
    new Date().setMinutes(Math.ceil(new Date().getMinutes() / 15) * 15)
  );
};

export const convertSemicolonStringTime = (time: string) => {
  const hour = +time?.substring(0, 2) * 60;
  const minute = +time?.substring(3, 5);
  const second = +time?.substring(6, 8) / 60;
  return (Number(hour) + Number(minute) + Number(second)).toFixed(0);
};

export const parseDateAndTime = (date: Date, time: string) => {
  return dayjs(date)
    .set("hour", Number(time?.substring(0, 2)))
    .set("minute", Number(time?.substring(3, 5)));
};

export const useForceUpdate = (): (() => void) => {
  const [, dispatch] = useState<{}>(Object.create(null));
  const memoizedDispatch = useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
};

export const objectToFormData = (object: anyObjectType) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const endScrollTrigger = (e: any, callback: Function): void => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {
    callback();
  }
};

export const base64toBlob = async (base64: string): Promise<Blob> => {
  return await fetch(base64).then((res) => res.blob());
};

export const blobToImage = (blob: Blob) => {
  return new File([blob], `filename-${blob.size}.png`, {
    type: blob.type,
  });
};

export const FiletoBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function imageToDataURL(
  src: string,
  outputFormat: "image/png" | "image/jpeg" | "image/webp",
  callback: Function,
  width: number,
  height: number
) {
  let img = new Image();
  img.src = src;
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    let canvas: any = document.createElement("CANVAS");
    let ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = height;
    canvas.width = width;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  if (img.complete || img.complete === undefined) {
    img.src = src;
  }
}
export const exportPageToPDF = async (
  page: HTMLDivElement,
  page_width: number,
  page_height: number,
  margin_top: number,
  orientation: "landscape" | "portrait",
  compressed_pdf?: boolean,
  document_name?: string
) => {
  page.style.backgroundColor = "white";
  await html2Image.toPng(page, { cacheBust: true }).then((blob) => {
    let image_width = page_width;
    let image_height = (page.scrollHeight * image_width) / page.clientWidth;
    let heightLeft = image_height;
    const doc = new jsPDF(orientation, "mm", "a4", compressed_pdf);
    let position = 0;
    doc.addImage(
      blob,
      "PNG",
      10,
      margin_top,
      image_width,
      image_height,
      "FAST"
    );
    heightLeft -= page_height;
    while (heightLeft >= 0) {
      position = heightLeft - image_height;
      doc.addPage();
      doc.addImage(
        blob,
        "PNG",
        10,
        position + margin_top,
        image_width,
        image_height,
        "FAST"
      );
      heightLeft -= page_height;
    }
    doc.save(`${document_name}.pdf`);
  });
};

export const savePdfFile = async (file_path: any, file_name: string) => {
  let a: any = document.createElement("A");
  let loading: boolean = true;
  a.href = file_path;
  a.download = file_name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  return (loading = false);
};

export const convertCurrenciesFormat = (
  locales: string,
  data: any,
  currency?: string
) => {
  const result = new Intl.NumberFormat(
    locales,
    currency
      ? {
          style: "currency",
          currency,
        }
      : undefined
  ).format(data);
  return result;
};

export const paginate = (
  array: any[],
  current_page: number,
  show_per_page: number
) => {
  return array.slice(
    (current_page - 1) * show_per_page,
    current_page * show_per_page
  );
};

export const getUniqValue = (array: any[], key: Function) => {
  let uniq = new Set();
  return array.filter((item) => {
    let k = key(item);
    return uniq.has(k) ? false : uniq.add(k);
  });
};

export const isValidDate = (dateString: string) => {
  if (dateString === null || dateString.trim() === "") {
    return false;
  }
  return dayjs(dateString).isValid();
};
