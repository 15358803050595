import { useContext } from "react";
import dayjs from "dayjs";

import { Modal } from "../../Global/Modal";
import { Button } from "../../Global/Button";
import { Icons } from "../../../themes/icons";

import { ReminderContext } from "../../../contexts/Reminder/ReminderContext";

import "./style.scss";

interface Props {}
export const ReminderPopUp: React.FC<Props> = () => {
	const { reminder_popup_data, show_pop_up_lists, setShowPopUpLists } =
		useContext(ReminderContext);

	const handleClick = () => {
		// setShowPopUpLists([...show_pop_up_lists, false]);
		// setReminderSelectedDetail({
		// 	...reminder_selected_detail,
		// 	data: { ...reminder_selected_detail.data, status: "done" },
		// });
		setShowPopUpLists(false);
	};

	return (
		<Modal
			title={`reminder`}
			show={show_pop_up_lists}
			handleClose={() => setShowPopUpLists(false)}
			width="35%">
			<div className="reminder-pop-up-modal">
				<img
					className="reminder-pop-up-modal__warning-img"
					src={Icons.warning_red}
					alt="warning"
					width="60px"
				/>
				<div>
					<p>{reminder_popup_data?.description}</p>
					<div className="show-flex vertical-center">
						<img
							src={Icons.clock_icon_green}
							alt="time"
							width="20px"
							height="20px"
						/>
						<b className="reminder-pop-up-modal__time">
							{dayjs(reminder_popup_data?.date_time).format(" HH:mm ")}
						</b>
					</div>
				</div>
				<div />
				<Button
					width="120px"
					text="done"
					btn_color="primary"
					clickHandler={handleClick}
					style={{ justifySelf: "end" }}
				/>
			</div>
		</Modal>
	);
};
