import React from "react";
import "./style.scss";
import { Images } from "../../../themes/images";
import { Icons } from "../../../themes/icons";

export const LogoSidebar: React.FC<any> = ({ is_collapse }) => {
	return (
		<div className="sidebar-logo">
			<img
				src={!is_collapse ? Images.logoTransparent : Icons.logo_icon}
				alt="logo"
				width={!is_collapse ? 100 : 50}
			/>
		</div>
	);
};

export default LogoSidebar;
