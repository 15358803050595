import React, { Fragment, useState } from "react";

import { sidebar_preference } from "../../utils/localStorage";

import Header from "../Header";
import Sidebar from "../Sidebar";

import "./style.scss";

interface Props {}

const MainContainer: React.FC<Props> = (props) => {
	const [is_collapse, setCollapse] = useState(sidebar_preference.isCollapsed());

	return (
		<Fragment>
			<Header is_collapse={is_collapse} />
			<Sidebar is_collapse={is_collapse} setCollapse={setCollapse} />
			<div className={`main-container ${is_collapse ? "full-width" : ""}`}>
				<div>{props.children}</div>
			</div>
		</Fragment>
	);
};

export default MainContainer;
