import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CURRENT_WEB_VERSION } from "./utils/constant/general";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./themes/styles.scss";

Sentry.init({
	release: "atto-web@" + CURRENT_WEB_VERSION,
	dsn: process.env.REACT_APP_SENTRY_DSN,
	autoSessionTracking: true,
	replaysOnErrorSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	tracesSampleRate: 1.0,
	environment: process.env.NODE_ENV,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals("");
