import React, { useState } from "react";
import { sidebar_preference } from "../../../utils/localStorage";

import "./style.scss";

interface Props {
	is_collapse: boolean;
	setCollapse: Function;
}

export const ToggleButtonSidebar: React.FC<Props> = ({
	is_collapse,
	setCollapse,
}) => {
	const [onHover, setOnHover] = useState(false);

	const toggleHandler = () => {
		setCollapse(!is_collapse);
		sidebar_preference.setCollapsed(!is_collapse);
	};

	return (
		<span
			className={`sidebar-toggle-button ${onHover && "on-hover"}`}
			onClick={() => toggleHandler()}
			onMouseEnter={() => setOnHover(true)}
			onMouseLeave={() => setOnHover(false)}
			title="hide sidebar"
		>
			{is_collapse ? (
				<i className="arrow right" />
			) : (
				<i className="arrow left" />
			)}
		</span>
	);
};

export default ToggleButtonSidebar;
