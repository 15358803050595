import { generatePath } from "react-router";

export const urlPath = {
	Dashboard: {
		Root: {
			name: "Dashboard",
			path: "/",
		},
		Dashboard: {
			name: "Dashboard",
			path: "/dashboard/",
		},
		Detail: {
			name: "Detail Status",
			path: "/dashboard/detail/",
			full_path: "/dashboard/detail/:type/:status",
		},
		TabPath: {
			Reminder: {
				name: "Reminder",
				path: "reminder",
			},
			Event: {
				name: "Event",
				path: "event",
			},
			EventScheduled: {
				name: "Event Scheduled",
				path: "event-scheduled",
			},
			LostFound: {
				name: "Found,Lost,Package",
				path: "lost-found",
			},
			Workorder: {
				name: "Work Order Corrective",
				path: "workorder",
			},
			WorkorderPreventive: {
				name: "Work Order Preventive",
				path: "workorder-preventive",
			},
			Asset: {
				name: "Asset",
				path: "asset",
			},
		},
	},
	Events: {
		Root: {
			name: "Internal & Guest Event",
			path: "/event/",
		},
		EventLists: {
			name: "Event",
			path: "/event/lists/",
		},
		EventDetail: {
			name: "Event Detail",
			path: "/event/detail/",
			full_path: "/event/detail/:id",
		},
	},
	EventScheduled: {
		Root: {
			name: "Event Scheduled",
			path: "/event/scheduled/",
		},
		EventScheduledDetail: {
			name: "Event Scheduled Detail",
			path: "/event/scheduled/detail/",
			full_path: "/event/scheduled/detail/:id",
		},
	},
	EventLocationOverview: {
		Root: {
			name: "Location Overview",
			path: "/event/location-overview/",
		},
		EventLocationOverviewDetail: {
			name: "Event Location Overview Detail",
			path: "/event/location-overview/detail/",
			full_path: "/event/location-overview/detail/:id",
		},
	},
	Notification: {
		Root: {
			name: "Notification",
			path: "/notification/",
		},
	},
	Profile: {
		Root: {
			name: "Profile",
			path: "/profile/",
		},
	},
	Package: {
		Root: {
			name: "Package",
			path: "/package/",
		},
	},
	Settings: {
		Root: {
			name: "Settings",
			path: "/settings",
		},
		TabPath: {
			UserDepartment: {
				name: "User & Department",
				path: "user-department",
			},
			LocationArea: {
				name: "Location & Area",
				path: "location-area",
			},
			TagCategory: {
				name: "Tag & Category",
				path: "tag-category",
			},
			Role: {
				name: "Role",
				path: "role",
			},
			Priority: {
				name: "Priority",
				path: "priority",
			},
			Checklist: {
				name: "Checklist",
				path: "checklist",
			},
			Cause: {
				name: "Cause",
				path: "cause",
			},
			Tools: {
				name: "Tools",
				path: "tools",
			},
			TestComm: {
				name: "Testcomm",
				path: "testcomm",
			},
			PerfectRoom: {
				name: "Perfect Room",
				path: "perfect-room",
			},
			ServiceCost: {
				name: "Service Cost",
				path: "service-cost",
			},
			Shift: {
				name: "Shift",
				path: "shift",
			},
			EventType: {
				name: "Event Type",
				path: "event-type",
			},
			EventTypeSelectTag: {
				name: "Select Tag",
				path: "/event-type/select-tag",
			},
			Subscription: {
				name: "Subscription",
				path: "subscription",
			},
			ClientSubscription: {
				Root: {
					name: "Client Subscription",
					path: "client-subscription",
				},
				Detail: {
					name: "Detail Client",
					path: "/settings/client-subscription/",
					full_path: "/settings/client-subscription/:id",
				},
			},
		},
		AmsTagCategory: {
			Create: {
				name: "Create Tag Category",
				path: "/settings/tag-category/create/",
				full_path: "/settings/tag-category/create/:id",
			},
			Detail: {
				name: "Detail Tag Category",
				path: "/settings/tag-category/detail/",
				full_path: "/settings/tag-category/detail/:id",
			},
		},
	},
	LocationOverview: {
		Root: {
			name: "Location Overview",
			path: "/location-overview/",
		},
		LocationOverviewAsset: {
			menu_name: "Asset",
			name: "Perfect Room Asset",
			path: "/location-overview/asset/",
		},
		LocationOverviewActivity: {
			menu_name: "Activity",
			name: "Activity",
			path: "/location-overview/activity",
			tab_path: [
				{
					name: "Event List",
					path: "event-list",
				},
				{
					name: "Work Order List",
					path: "work-order",
				},
			],
		},
	},
	LostAndFound: {
		Root: {
			name: "Lost And Found",
			path: "/lost-and-found/",
		},
	},
	Housekeeping: {
		Root: {
			name: "Housekeeping",
			path: "/housekeeping/",
		},
	},
	Reminder: {
		Root: {
			name: "Reminder",
			path: "/reminder/",
		},
	},
	Reports: {
		Root: {
			name: "Reports",
			path: "/reports/",
		},
		Event: {
			List: {
				name: "Event Report List",
				path: "/reports/event-report",
			},
			Detail: {
				name: "Event Report Detail",
				path: "/reports/event-report/detail",
				full_path: "/reports/event-report/detail/:id",
			},
		},
		EventLocation: {
			name: "Event",
			path: "/reports/event/location",
		},
		EventEmployee: {
			name: "Event",
			path: "/reports/event/employee",
		},
		EventStatus: {
			name: "Event",
			path: "/reports/event/status",
		},
		EventTag: {
			name: "Event",
			path: "/reports/event/tag",
		},
		EventDetail: {
			name: "Event",
			path: "/reports/event/detail",
		},
		EventDetailPage: {
			name: "Event",
			path: "/reports/event/detail/:id",
		},
		TagLocation: {
			name: "Tag",
			path: "/reports/tag/location",
		},
		TagSummary: {
			name: "Tag",
			path: "/reports/tag/summary",
		},
		Employee: {
			RMS: {
				List: {
					name: "Employee List - RMS",
					path: "/reports/rms-employee-list",
				},
				Detail: {
					name: "Employee Details",
					path: "/reports/rms-employee-list/detail/",
					full_path: "/reports/rms-employee-list/detail/:id",
				},
			},
			AMS: {
				List: {
					name: "Employee List - AMS",
					path: "/reports/ams-employee-list",
				},
				Detail: {
					name: "Employee Details",
					path: "/reports/ams-employee-list/detail/",
					full_path: "/reports/ams-employee-list/detail/:id",
				},
			},
		},
		Workorder: {
			Detail: {
				name: "Workorder Detail",
				path: "/reports/workorder-list/detail",
				full_path: "/reports/workorder-list/detail/:id",
			},
			Root: {
				name: "Workorder List",
				path: "/reports/workorder-list",
			},
		},
		AssetList: {
			Root: {
				name: "Asset List",
				path: "/reports/asset/",
			},
			Detail: {
				name: "Asset Detail",
				path: "/reports/asset/detail",
				full_path: "/reports/asset/detail/:id",
			},
		},
		History: {
			name: "History",
			path: "/reports/history/",
		},
		Package: {
			List: {
				name: "Package List",
				path: "/reports/package-report/",
			},
			Detail: {
				name: "Package Detail",
				path: "/reports/package-report/detail",
				full_path: "/reports/package-report/detail/:id",
			},
		},
		Lost: {
			List: {
				name: "Lost List",
				path: "/reports/lost-report/",
			},
			Detail: {
				name: "Lost Detail",
				path: "/reports/lost-report/detail",
				full_path: "/reports/lost-report/detail/:id",
			},
		},
		Found: {
			List: {
				name: "Found List",
				path: "/reports/found-report/",
			},
			Detail: {
				name: "Found Detail",
				path: "/reports/found-report/detail",
				full_path: "/reports/found-report/detail/:id",
			},
		},
		Reminder: {
			List: {
				name: "Reminder List",
				path: "/reports/reminder-report/",
			},
			Detail: {
				name: "Reminder Detail",
				path: "/reports/reminder-report/detail",
				full_path: "/reports/reminder-report/detail/:id",
			},
		},
		Sparepart: {
			List: {
				name: "Sparepart List",
				path: "/reports/sparepart-report",
			},
			Detail: {
				name: "Sparepart Detail",
				path: "/reports/sparepart-report/detail",
				full_path: "/reports/sparepart-report/detail/:id",
			},
		},
		BASTHistory: {
			List: {
				name: "BAST History",
				path: "/reports/bast-history",
			},
		},
		ExpiredDate: {
			List: {
				name: "Expired Date",
				path: "/reports/expired-date",
			},
		},
		Shift: {
			List: {
				name: "Shift List",
				path: "/reports/shift",
			},
			WorkorderDetail: {
				name: "Workorder Detail",
				path: "/reports/shift/workorder-detail",
				full_path: "/reports/shift/workorder-detail/:id/:shift/:time",
			},
			EventDetail: {
				name: "Event Detail",
				path: "/reports/shift/event-detail",
				full_path: "/reports/shift/event-detail/:id/:shift/:time",
			},
		},
		TagAms: {
			List: {
				name: "AMS - Tag List",
				path: "/reports/tag-ams",
			},
			Summary: {
				name: "AMS - Tag Summary",
				path: "/reports/tag-ams/summary",
				full_path:
					"/reports/tag-ams/summary/:id/:left_date_summary/:right_date_summary",
			},
		},
		TagRms: {
			List: {
				name: "RMS - Tag List",
				path: "/reports/tag-rms",
			},
			Summary: {
				name: "RMS - Tag Summary",
				path: "/reports/tag-rms/summary",
				full_path:
					"/reports/tag-rms/summary/:id/:left_date_summary/:right_date_summary",
			},
		},
	},

	AssetManagement: {
		Root: {
			name: "Asset Management",
			path: "/ams/",
		},
		AssetConfiguration: {
			name: "Asset Configuration",
			path: "/ams/configuration/",
			tab_path: {
				CategoryType: {
					name: "Category & Type",
					path: "category-type",
				},
				Brand: {
					name: "Brand",
					path: "brand",
				},
				Status: {
					name: "Status",
					path: "status",
				},
			},
		},
		AssetManagementVendor: {
			Root: {
				name: "Vendor",
				path: "/ams/vendor/",
			},
			VendorDetail: {
				name: "Vendor Detail",
				path: "/ams/vendor/detail",
				full_path: "/ams/vendor/detail/:id",
			},
			VendorCreate: {
				name: "Create vendor",
				path: "/ams/vendor/create",
			},
			tab_path: {
				GeneralInformation: {
					name: "General Information",
					path: "general-information",
				},
				Vendor: {
					name: "Vendor",
					path: "vendor",
				},
				Similar: {
					name: "Similar",
					path: "similar",
				},
				Workorder: {
					name: "Work Order",
					path: "workorder",
				},
				Log: {
					name: "Log",
					path: "log",
				},
			},
		},
		AssetManagementPart: {
			name: "Spare Part",
			path: "/ams/sparepart/",
			CreateSparepart: {
				name: "Create Sparepart",
				path: "/ams/sparepart/create",
			},
			SparepartDetail: {
				name: "Sparepart Detail",
				path: "/ams/sparepart/detail",
				full_path: "/ams/sparepart/detail/:id",
			},
			tab_path: {
				GeneralInformation: {
					name: "General Information",
					path: "general-information",
				},
				Vendor: {
					name: "Vendor",
					path: "vendor",
				},
				Similar: {
					name: "Similar",
					path: "similar",
				},
				Workorder: {
					name: "Work Order",
					path: "workorder",
				},
				Log: {
					name: "Log",
					path: "log",
				},
			},
		},
		AssetManagementList: {
			Root: {
				name: "Asset List",
				path: "/ams/asset/",
			},
			DetailAsset: {
				name: "Asset Detail",
				path: "/ams/asset/detail/",
				full_path: "/ams/asset/detail/:id",
			},
			CreateAsset: {
				name: "Create Asset",
				path: "/ams/asset/create/",
				// full_path: "/ams/asset/create/:id",
			},
		},
		Items: {
			CreateItems: {
				name: "Create Items",
				path: "/items/create/",
				full_path: "/items/create/:id",
			},
			DetailItems: {
				name: "Detail Items",
				path: "/items/detail/",
				full_path: "/items/detail/:id",
			},
		},
	},
	Workorder: {
		Root: {
			name: "Work Order",
			path: "/workorder/",
		},
		WorkorderDetail: {
			name: "Workorder Detail",
			path: "/workorder/detail",
			full_path: "/workorder/detail/:id",
		},
		Corrective: {
			name: "Corrective",
			path: "/workorder/corrective/",
			tab_path: {},
		},
		Preventive: {
			name: "Preventive",
			path: "/workorder/preventive/",
		},
		PreventiveScheduling: {
			name: "Preventive Scheduling",
			path: "/workorder/preventive-scheduling/",
			CreatePreventiveScheduling: {
				name: "Preventive Scheduling",
				path: "/workorder/preventive-scheduling/add",
			},
			DetailPreventiveScheduling: {
				name: "Preventive Scheduling Detail",
				path: "/workorder/preventive-scheduling/detail",
				full_path: "/workorder/preventive-scheduling/detail/:id",
			},
			tab_path: {
				GeneralInformation: {
					name: "General Information",
					path: "general-information",
				},
				ChooseItem: {
					name: "Choose Item",
					path: "choose-item",
				},
				ChooseTime: {
					name: "Choose Time",
					path: "choose-time",
				},
				Attachment: {
					name: "Attachment",
					path: "attachment",
				},
				History: {
					name: "History",
					path: "history",
				},
			},
		},
	},
};
