import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MenuSidebarContext } from "../../../contexts/Menu/MenuSidebarContext";
import { urlPath } from "../../../utils/constant/urlPath";

import "./style.scss";

export const ListMenu: React.FC<any> = ({ is_collapse }) => {
	const { menus, show_submenu, setShowSubmenu } =
		useContext(MenuSidebarContext);
	const history = useHistory();
	const current_path = useLocation().pathname;

	return (
		<ul className="list-menu">
			{menus?.map((menu: any, menu_index: number) => {
				const is_active_menu = current_path === menu.main_menu.path;
				return (
					<React.Fragment key={menu_index}>
						<li
							onClick={() => {
								if (menu.main_menu.path === urlPath.Reports.Root.path) {
									window.location.replace(urlPath.Reports.Root.path);
									return;
								}
								menu.sub_menu.length > 0
									? setShowSubmenu(menu_index)
									: history.push(menu.main_menu.path);
								// if(menu.sub_menu.length > 0){
								// 	setShowSubmenu(menu_index)
								// } else{
								// 	history.push(menu.main_menu.path)
								// }
							}}
							className={`${is_collapse ? "sidebar-collapsed" : ""} 
							${is_active_menu ? "selected" : ""}
							`}
						>
							<img
								className={is_collapse ? "" : "sidebar-img-margin"}
								src={menu.main_menu.icon}
								alt="icon"
							/>
							{!is_collapse && menu.main_menu.name}
						</li>

						{menu.sub_menu &&
							menu.sub_menu.length > 0 &&
							menu.sub_menu.map((sub: any, sub_index: number) => {
								const is_active_sub_menu = current_path === sub.path;

								return (
									<ul
										className={`list-sub-menu ${
											current_path.split("/")[1] === sub.path.split("/")[1] ||
											show_submenu === menu_index
												? "show"
												: "hide"
										}`}
										key={sub_index}
									>
										<li
											className={`${
												is_collapse && "sub-menu-sidebar-collapsed"
											} ${is_active_sub_menu ? "selected" : ""}`}
											onClick={() => {
												history.push(sub.path);
											}}
										>
											<img
												className={is_collapse ? "" : "sidebar-img-margin"}
												src={sub.icon}
												alt="icon"
											/>
											{!is_collapse && sub.name}
										</li>
									</ul>
								);
							})}
					</React.Fragment>
				);
			})}
		</ul>
	);
};

export default ListMenu;
