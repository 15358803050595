import { paginationType } from "../contexts/PaginationContext";
import { reminderFormData } from "../interfaces/rms/reminder_interfaces";
import api from "./api-config";
import api_constant from "./api-constant";

export const addReminder = async (form_data: reminderFormData) => {
	const data = {
		assign_to: form_data.assign_to,
		date_time: form_data.data.date_time,
		description: form_data.data.description,
	};
	const { method, request_name, api_path }: any =
		api_constant.reminder.addReminder;
	try {
		return await api({
			method,
			request_name,
			api_path,
			data: data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const editReminder = async (form_data: reminderFormData, id: string) => {
	const data = {
		assign_to: form_data.assign_to,
		date_time: form_data.data.date_time,
		description: form_data.data.description,
		status: form_data.data.status,
	};
	const { method, request_name, api_path }: any =
		api_constant.reminder.editReminder;
	try {
		return await api({
			method,
			request_name,
			api_path,
			data: data,
			params: { id },
		});
	} catch (e) {
		console.log(e);
	}
};

export const getReminderLists = async (
	pagination: paginationType,
	is_past: "past" | "recent"
) => {
	const { method, request_name, api_path }: any =
		api_constant.reminder.getReminderLists;
	try {
		return await api({
			method,
			request_name,
			api_path,
			params: { pagination, is_past },
		});
	} catch (e) {
		console.log(e);
	}
};
