import React from "react";
import { Icons } from "../../../themes/icons";

import "./style.scss";

interface Props {
	title: string;
	show: boolean;
	handleClose: Function;
	style?: object;
	width?: string;
	overflow_visible?: boolean;
}

export const Modal: React.FC<Props> = ({
	children,
	title,
	show,
	handleClose,
	width,
	style,
	overflow_visible = false,
}) => {
	return (
		<div className={`modal ${show ? "show-flex" : ""}`}>
			<div className="modal-container" style={{ width }}>
				<div className="modal-head">
					<p>{title}</p>
					<span
						style={{
							display: "flex",
							justifyContent: "center",
							position: "absolute",
							top: "-10px",
							right: "-10px",
							height: "25px",
							width: "25px",
							backgroundColor: "white",
							color: "#F7685B",
							borderRadius: "25px",
							cursor: "pointer",
						}}
						title="close modal"
						onClick={() => handleClose()}
					>
						<img src={Icons.close_icon} alt="" />
					</span>
				</div>
				<div
					className="modal-body"
					style={Object.assign(
						style || {},
						overflow_visible ? { overflow: "visible" } : {}
					)}
				>
					{children}
				</div>
			</div>
		</div>
	);
};
