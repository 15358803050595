import React, { useContext, useEffect } from "react";

import { PaginationContext } from "../../../contexts/PaginationContext";

import { Button } from "../Button";

import "./style.scss";

interface Props {
	getPaginationSetting?: Function;
	total_column?: number;
	total_item?: number;
	show_view_per_page_option?: boolean;
}

export const TablePagination: React.FC<Props> = ({
	getPaginationSetting,
	total_column,
	total_item,
	show_view_per_page_option = true,
}) => {
	const { pagination, setPagination } = useContext(PaginationContext);
	const { show_per_page, current_page } = pagination;

	getPaginationSetting && getPaginationSetting(pagination);

	return (
		<tr className="talent-pool-pagination">
			<td
				colSpan={
					total_column
						? show_view_per_page_option
							? total_column - 2
							: total_column - 1
						: 6
				}
			></td>
			{show_view_per_page_option && (
				<td>
					<span>Rows per page:</span>
					<select
						className="show-per-page"
						onChange={(e) =>
							setPagination({
								...pagination,
								show_per_page: Number(e.target.value),
							})
						}
					>
						<option disabled>{show_per_page}</option>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={30}>30</option>
					</select>
				</td>
			)}
			<td>
				<div className="navigation">
					<Button
						text="<"
						btn_color="white"
						style={style.pagination}
						clickHandler={() =>
							current_page > 1 &&
							setPagination({
								...pagination,
								current_page: pagination.current_page - 1,
							})
						}
					/>
					<div>
						<span>{(current_page - 1) * show_per_page + 1}</span>
						<span>{" - "}</span>
						<span>{current_page * show_per_page}</span>
						{/* <span>{" of "}</span>
						<span>{total_item}</span> */}
					</div>
					<Button
						text=">"
						btn_color="white"
						style={style.pagination}
						clickHandler={() =>
							// total_item && ((current_page + 1) * show_per_page < total_item) &&
							setPagination({
								...pagination,
								current_page: pagination.current_page + 1,
							})
						}
					/>
				</div>
			</td>
		</tr>
	);
};

const style = {
	pagination: {
		width: "auto",
		margin: 0,
		background: "none",
		boxShadow: "none",
	},
};
