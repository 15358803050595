import { notificationViewType } from "./../interfaces/rms/notification_interfaces";
import { paginationType } from "./../contexts/PaginationContext";
import api from "./api-config";
import api_constant from "./api-constant";

export const getLists = async (
	pagination: paginationType,
	view_type: notificationViewType
) => {
	const { method, request_name, api_path }: any =
		api_constant.notification.getLists;
	try {
		return await api({
			method,
			request_name,
			api_path,
			params: { pagination, view_type },
		});
	} catch (e) {
		console.log(e);
	}
};

export const read = async (notification_id: string) => {
	const { method, request_name, api_path }: any =
		api_constant.notification.read;
	try {
		return await api({
			method,
			request_name,
			api_path,
			data: { notification_id },
		});
	} catch (e) {
		console.log(e);
	}
};
