import React, { createContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/remote-config";
// import "firebase/analytics";
import { addFCMToken } from "../services/general/UserService";
import { CURRENT_WEB_VERSION } from "../utils/constant/general";

const firebaseConfig = {
	apiKey: "AIzaSyD6DEKW-pM48NmUzjdgQvkxAu6EoXc6Qq4",
	authDomain: "atto-30c82.firebaseapp.com",
	projectId: "atto-30c82",
	storageBucket: "atto-30c82.appspot.com",
	messagingSenderId: "334739930832",
	appId: "1:334739930832:web:0b12e932c161946f16e8d5",
	measurementId: "G-DB3E74208D",
};
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
const messaging = firebase.messaging();

const remote_config_default_data: RemoteConfigData = {
	WEB_APPS_UPDATE: {
		version: CURRENT_WEB_VERSION,
		required: false,
	},
};

interface firebaseNode {
	fcm_token: string;
	payload: {
		data: any;
		notification: {
			body: string;
			title: string;
		};
	};
	setPayload: Function;
	saveFCMToken: Function;

	remote_config_data: RemoteConfigData;
}
const FirebaseContext = createContext({} as firebaseNode);

interface Props {}
const FirebaseProvider: React.FC<Props> = ({ children }) => {
	const [fcm_token, setFCMToken] = useState<string>("");
	const [payload, setPayload] = useState<any>({});

	const [remote_config_data, setRemoteConfigData] = useState<RemoteConfigData>(
		remote_config_default_data
	);

	const getFCMToken = async (): Promise<string> => {
		return await messaging.getToken({
			vapidKey:
				"BH3d3Md7imfHOdNcs4OeO-WoKjBQbnWIqtNLX1NQuvX1L4i6MG0x0Ipqjcx--7HErpsVDosJ6iLlNLWqUw2Flik",
		});
	};

	const saveFCMToken = async () => {
		try {
			const get_fcm_token = await getFCMToken();
			setFCMToken(get_fcm_token); // save to context
			await addFCMToken(get_fcm_token); // save to server
		} catch (e) {
			console.log(e);
		}
	};

	messaging.onMessage((payload) => {
		setPayload(payload);
		console.log("Message received. ", payload);
	});

	const remoteConfig = firebase.remoteConfig();

	// REMOTE CONFIG
	remoteConfig.settings = {
		// minimumFetchIntervalMillis: 3600000,
		// fetchTimeoutMillis: 60000
		fetchTimeoutMillis: 60000, // One min before timing out while fetching
		minimumFetchIntervalMillis: 10000, // very short interval for fields expiration.
	};

	remoteConfig.defaultConfig = {
		WEB_APPS_UPDATE: JSON.stringify(remote_config_default_data.WEB_APPS_UPDATE),
	};

	useEffect(() => {
		remoteConfig
			.fetchAndActivate()
			.then(() => {
				return remoteConfig.getAll();
			})
			.then((result) => {
				const updated_remote_config_data: any = {
					...remote_config_data,
				};

				for (const [key_update, value] of Object.entries(result)) {
					for (const [key] of Object.entries(updated_remote_config_data)) {
						if (key === key_update) {
							updated_remote_config_data[key] = JSON.parse(value.asString());
						}
					}
				}

				setRemoteConfigData(updated_remote_config_data);
			});
	}, []);

	return (
		<FirebaseContext.Provider
			value={{
				fcm_token,
				payload,
				setPayload,
				saveFCMToken,
				remote_config_data,
			}}
		>
			{children}
		</FirebaseContext.Provider>
	);
};

interface RemoteConfigData {
	WEB_APPS_UPDATE: {
		version: string;
		required: boolean;
	};
}

export { FirebaseContext, FirebaseProvider };
