import React, { createContext, useContext, useEffect, useState } from "react";

import {
	notificationViewType,
	notificationListType,
	notification_list_init,
} from "../../interfaces/rms/notification_interfaces";
import * as NotificationService from "../../services/notificationService";
import { FirebaseContext } from "../FirebaseContext";
import { PaginationContext } from "../PaginationContext";

interface NotificationNode {
	notification_lists: notificationListType[];
	setNotificationLists: Function;

	notification_selected_id: string;
	setNotificationSelectedId: Function;

	readNotification: Function;

	unread_count: number;
	setUnreadCount: Function;

	view_type: notificationViewType;
	setViewType: Function;
}

const NotificationContext = createContext({} as NotificationNode);

interface Props {}
const NotificationProvider: React.FC<Props> = ({ children }) => {
	const { pagination } = useContext(PaginationContext);
	const { payload } = useContext(FirebaseContext);
	const [view_type, setViewType] = useState<notificationViewType>("all");
	const [notification_lists, setNotificationLists] = useState<
		notificationListType[]
	>([notification_list_init]);

	const [notification_selected_id, setNotificationSelectedId] = useState("");

	const [unread_count, setUnreadCount] = useState<number>(0);

	const getLists = () => {
		NotificationService.getLists(pagination, view_type).then((data: any) => {
			const notif_data: notificationListType[] = data;
			setNotificationLists(notif_data);
			const count_unread = notif_data.filter(
				(notif) => notif.is_read === false
			)?.length;
			setUnreadCount(count_unread || 0);
		});
	};
	useEffect(getLists, [pagination, view_type]);

	const readNotification = async (notification_id: string) => {
		try {
			await NotificationService.read(notification_id);
			getLists();
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (payload?.data?.service === "rms" && payload?.data?.topic === "event") {
			setNotificationLists([
				...notification_lists,
				{
					id: "",
					description: payload?.data?.description,
					event_id: payload?.data?.event_id,
					created_at: new Date(),
					is_read: false,
					user_id: "",
				},
			]);
			setUnreadCount(unread_count + 1);
		}
		return () => {};
	}, [payload]); // ignore false positive

	return (
		<NotificationContext.Provider
			value={{
				notification_lists,
				setNotificationLists,

				notification_selected_id,
				setNotificationSelectedId,

				readNotification,

				unread_count,
				setUnreadCount,

				view_type,
				setViewType,
			}}>
			{children}
		</NotificationContext.Provider>
	);
};

export { NotificationContext, NotificationProvider };
