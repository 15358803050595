import signout from "../assets/icons/signout.svg";
import login_bg from "../assets/images/login_bg.png";
import logo from "../assets/images/logo.png";
import logoTransparent from "../assets/images/logo-transparent.png";
import upload_image from "../assets/images/upload_image.png";

// sample
import comment_img from "../assets/images/sample/comment_img.png";

export const Images = {
	signout,
	login_bg,
	logo,
	logoTransparent,
	upload_image,
	comment_img,
};
