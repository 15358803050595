import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../../../contexts/FirebaseContext";
import {
	CURRENT_WEB_VERSION,
	message_update_version,
} from "../../../utils/constant/general";
import { Button } from "../Button";
import { Modal } from "../Modal";

export const ModalUpdateVersion: React.FC = () => {
	const { remote_config_data } = useContext(FirebaseContext);

	const [modal_show, setModalShow] = useState<boolean>(false);

	useEffect(() => {
		if (CURRENT_WEB_VERSION !== remote_config_data.WEB_APPS_UPDATE.version) {
			setModalShow(true);
		}
	}, [remote_config_data]);

	return (
		<Modal
			title="Update Application"
			show={modal_show}
			handleClose={() => window.location.reload()}
		>
			<div>
				<p
					style={{
						marginBottom: "10px",
					}}
				>
					{message_update_version}
				</p>
				<p
					style={{
						marginBottom: "20px",
					}}
				>
					{`\nYour version: ${CURRENT_WEB_VERSION} Updated version: ${remote_config_data.WEB_APPS_UPDATE.version}`}
				</p>
			</div>
			<Button
				text="Update"
				btn_color="primary"
				style={{
					float: "right",
				}}
				clickHandler={() => window.location.reload()}
			/>
		</Modal>
	);
};
