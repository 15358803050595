import { paginationType } from "./../../contexts/PaginationContext";
// import { paginationType } from "./../contexts/PaginationContext";
import { userType } from "../../interfaces/user_interfaces";
import api from "../api-config";
import api_constant from "../api-constant";

export const addUser = async (form_data: userType) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.addUser;
  try {
    return await api({
      method,
      request_name,
      api_path,
      data: form_data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const editUser = async (form_data: userType) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.editUser;
  try {
    return await api({
      method,
      request_name,
      api_path,
      data: form_data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (id: string) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getUser;
  try {
    return await api({
      method,
      request_name,
      api_path,
      params: { id },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getMultipleUser = async (ids: string[]) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getMultipleUser;
  try {
    return await api({
      method,
      request_name,
      api_path,
      params: { ids },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserLists = async (
  pagination: paginationType,
  search: string,
  department_ids?: string[]
) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getUserLists;
  try {
    return await api({
      method,
      request_name,
      api_path,
      params: { search, pagination },
      data: {
        filter: { department_ids },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserByDepartment = async (department_id: string) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getUserByDepartment;
  try {
    return await api({
      method,
      request_name,
      api_path,
      params: { department_id },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserByCompany = async () => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getUserByCompany;
  try {
    return await api({
      method,
      request_name,
      api_path,
    });
  } catch (e) {
    console.log(e);
  }
};

export const addFCMToken = async (fcm_token: string) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.addFCMToken;
  try {
    return await api({
      method,
      request_name,
      api_path,
      data: { fcm_token },
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeFCMToken = async (fcm_token: string) => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.removeUserFCMToken;
  try {
    return await api({
      method,
      request_name,
      api_path,
      data: { fcm_token },
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUserDepartments = async () => {
  const { method, request_name, api_path }: any =
    api_constant.general.user.getUserDepartments;
  try {
    return await api({
      method,
      request_name,
      api_path,
    });
  } catch (e) {
    console.log(e);
  }
};
