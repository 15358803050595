import React, { createContext, useEffect, useState } from "react";
import { filterType, filter_init } from "../interfaces/general_interface";
import { useForceUpdate } from "../utils/helper";

interface FilterContextNode {
	filter: filterType;
	setFilterObject: Function;
}
const FilterContext = createContext({} as FilterContextNode);

interface Props {}
const FilterProvider: React.FC<Props> = ({ children }) => {
	const [filter, setFilter] = useState<filterType>(filter_init);

	const setFilterObject = (filter_object: filterType) => {
		const new_filter = Object.assign(filter, filter_object);
		setFilter({ ...filter, ...new_filter });
	};

	// setFilter to empty on component will unmount & did mount
	// useEffect(() => {
	// 	setFilter({});
	// 	return () => setFilter({});
	// }, []);

	return (
		<FilterContext.Provider
			value={{
				filter,
				setFilterObject,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export { FilterContext, FilterProvider };
