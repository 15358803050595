import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router";

import { storeToken, removeToken } from "../../utils/localStorage";
import { loginService } from "../../services/auth";
import { removeFCMToken } from "../../services/general/UserService";
import { AlertContext } from "../Alert/AlertContext";
import { FirebaseContext } from "../FirebaseContext";
import { urlPath } from "../../utils/constant/urlPath";

interface LoginNode {
	phone: string;
	setPhone: Function;
	password: string;
	setPassword: Function;
	submitLogin: Function;
	is_loading: boolean;
	setIsLoading: Function;
	Logout: Function;
}
const AuthContext = createContext({} as LoginNode);

const AuthProvider: React.FC = (props) => {
	const history = useHistory();

	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [is_loading, setIsLoading] = useState(false);

	const { alert_detail, setAlertDetail } = useContext(AlertContext);
	const { saveFCMToken, fcm_token } = useContext(FirebaseContext);

	const submitLogin = async (phone: string, password: string) => {
		setIsLoading(true);
		try {
			const result: any = await loginService(phone, password);
			if (result) {
				setIsLoading(false);
				if (result.status === "Failed") {
					setAlertDetail({
						...alert_detail,
						type: "warning",
						message: result.message,
						is_show: true,
					});
					setIsLoading(false);
				} else {
					storeToken(result); // store token first
					await saveFCMToken(); // save fcm token based id on user token
					history.push(urlPath.Dashboard.Root.path);
					setIsLoading(false);
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const Logout = async () => {
		try {
			await removeFCMToken(fcm_token); // remove fcm token on server first
			removeToken(); // remove token on client
			history.push("/login");
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				phone,
				setPhone,
				password,
				setPassword,
				submitLogin,
				is_loading,
				setIsLoading,
				Logout,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
