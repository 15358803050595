import api from "./api-config";
import api_constant from "./api-constant";

const { baseURL, api_path, method, request_name }: any =
	api_constant.auth.login;

export const loginService = async (phone: string, password: string) => {
	const result = await api({
		baseURL,
		api_path,
		method,
		request_name,
		data: { phone, password },
	});
	return result;
};
