export const CURRENT_WEB_VERSION = "1.0.6";
export const message_update_version =
	"Hey, we just release a new version.\nFor your convenience, any action will canceled and this page will be reload. Press Update to continue";

export const ten_color_pallete = [
	"#0ff1ce",
	"#bada55",
	"#7fe5f0",
	"#ff80ed",
	"#407294",
	"#cbcba9",
	"#133337",
	"#065535",
	"#c0c0c0",
	"#420420",
];

const { protocol, hostname, port } = window.location;

export const base_app_url = `${protocol}//${hostname}${
	port ? ":" + window.location.port : ""
}/`;
export const base_url = process.env.REACT_APP_API_URL;
export const base_url_V1 = process.env.REACT_APP_API_URL_V1;
export const base_url_ams = process.env.REACT_APP_API_URL_AMS_V1;
export const base_url_ams_v1 = process.env.REACT_APP_API_URL_AMS;
