export const containerPath = {
	Dashboard: "containers/Dashboard",
	Event: "containers/Events/Event/",
	EventDetail: "containers/Events/NewEventDetail/",
	EventScheduled: "containers/Events/EventScheduled/",
	EventScheduledDetail: "containers/Events/EventScheduledDetail/",
	EventLocationOverview: "containers/Events/EventLocationOverview/",
	LocationOverview: "containers/LocationOverview",
	LostAndFound: "containers/LostAndFound/",
	Package: "containers/Package/",
	Reminder: "containers/Reminder/",
	// AMS START //
	AssetManagement: "containers/Ams/",
	AmsAssetConfiguration: "containers/Ams/AmsAssetConfiguration/",
	AmsVendor: "containers/Ams/AmsVendor/",
	AmsVendorDetail: "containers/Ams/AmsVendor/DetailVendor/",
	AmsSparepart: "containers/Ams/AmsSparepart/",
	AmsSparepartDetail: "containers/Ams/AmsSparepart/DetailSparepart/",
	AmsAssetList: "containers/Ams/AmsAssetList/",
	AmsCreateAsset: "containers/Ams/AmsCreateAsset/",
	// ITEMS
	AmsCreateItems: "containers/Ams/AmsItem/DetailItem/",
	//// WORKORDER
	Corrective: "containers/Ams/Workorder/Corrective/",
	WorkorderDetail: "containers/Ams/Workorder/NewWorkorderDetail",
	Preventive: "containers/Ams/Workorder/Preventive/",
	PreventiveScheduling: "containers/Ams/Workorder/PreventiveScheduling/",
	PreventiveSchedulingDetail:
		"containers/Ams/Workorder/PreventiveSchedulingDetail/",
	// AMS END //

	/* Reports START */
	Reports: "containers/Reports/",
	ReportsEventLocation: "containers/Reports/Event/Location/",
	ReportsEventEmployee: "containers/Reports/Event/Employee/",
	ReportsEventStatus: "containers/Reports/Event/Status",
	ReportsEventTag: "containers/Reports/Event/Tag",
	ReportsEventDetail: "containers/Reports/Event/Detail/",
	ReportsEventDetailPage: "containers/Reports/Event/DetailPage/",
	ReportsTagLocation: "containers/Reports/Tag/Location/",
	ReportsTagSummary: "containers/Reports/Tag/Summary/",
	ReportsEmployee: "containers/Reports/Employee/List",
	ReportsEmployeeDetail: "containers/Reports/Employee/Detail",
	ReportsEmployeeAms: "containers/Reports/Ams/Employee/List",
	ReportsEmployeeDetailAms: "containers/Reports/Ams/Employee/Detail",
	// AMS
	ReportsWorkorder: "containers/Reports/Ams/Workorder/List",
	ReportsWorkorderDetail: "containers/Reports/Ams/Workorder/Detail",
	ReportsAssetList: "containers/Reports/Ams/Asset/List",
	ReportsAssetDetail: "containers/Reports/Ams/Asset/Detail",
	// TAG
	ReportAmsTagList: "containers/Reports/Tag/List/Ams",
	ReportAmsTagSummary: "containers/Reports/Tag/Summary/Ams",
	ReportRmsTagList: "containers/Reports/Tag/List/Rms",
	ReportRmsTagSummary: "containers/Reports/Tag/Summary/Rms",
	// History
	ReportsHistory: "containers/Reports/History",
	// Event
	ReportsEventList: "containers/Reports/Event/List",
	ReportsEventDetails: "containers/Reports/Event/DetailReport",
	// Package
	ReportPackageList: "containers/Reports/Package/List",
	ReportPackageDetail: "containers/Reports/Package/Detail",
	// Lost
	ReportLostList: "containers/Reports/LostAndFound/List/Lost",
	ReportLostDetail: "containers/Reports/LostAndFound/Detail/Lost",
	// Found
	ReportFoundList: "containers/Reports/LostAndFound/List/Found",
	ReportFoundDetail: "containers/Reports/LostAndFound/Detail/Found",
	// Reminder
	ReportReminderList: "containers/Reports/Reminder/List",
	ReportReminderDetail: "containers/Reports/Reminder/Detail",
	// Sparepart
	ReportSparepartList: "containers/Reports/Ams/Sparepart",
	// BAST History
	ReportBASTHistory: "containers/Reports/Ams/BASTHistory",
	// Expired Date
	ReportExpiredDate: "containers/Reports/Ams/ExpiredDate",
	// Shift Report
	ReportShiftList: "containers/Reports/Shift",
	/* Reports END */

	Notification: "containers/Notification",
	Profile: "containers/Profile",
	Settings: "containers/Settings",
	SettingTagCategoryAms: "containers/Ams/Settings/TagCategory",

	// EVENT TYPE
	EventTypeSelectTag: "containers/Settings/EventType/SelectTagContainer",
	DetailClientSubscription: "containers/Settings/ClientSubscription/Detail",
};
