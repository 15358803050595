import React from "react";

import { TopHeader } from "../../components/Header/TopHeader/";

import "./style.scss";

interface Props {
	is_collapse: boolean;
}

export const Header: React.FC<Props> = ({ is_collapse }) => {
	return (
		<div className={`header-container ${is_collapse ? "full-width" : ""}`}>
			<TopHeader />
			{/* <MainMenu /> */}
			{/* <SubMenu /> */}
		</div>
	);
};

export default Header;
