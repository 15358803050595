import React, { useContext } from "react";

import { AlertContext } from "../../../contexts/Alert/AlertContext";
import { Icons } from "../../../themes/icons";

import "./style.scss";

interface Props {}

export const Alert: React.FC<Props> = () => {
	const { alert_detail } = useContext(AlertContext);
	return (
		<div
			className={`alert-component ${
				alert_detail.is_show ? "show-flex" : "hide"
			}`}
		>
			<div className={`message-box ${alert_detail.type}`}>
				{alert_detail.type === "success" && (
					<img className="alert-icon" src={Icons.check_green} alt="check" />
				)}
				<span className="message">{alert_detail.message}</span>
			</div>
		</div>
	);
};
