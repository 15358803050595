import React, { createContext, useState } from "react";

export type paginationType = {
	current_page: number;
	show_per_page: number;
};

interface PaginationContextNode {
	pagination: paginationType;
	setPagination: Function;
	total_item: number;
	setTotalItem: Function;
}
const PaginationContext = createContext({} as PaginationContextNode);

const PaginationProvider: React.FC = ({ children }) => {
	const init_pagination: paginationType = {
		current_page: 1,
		show_per_page: 10,
	};

	const [pagination, setPagination] = useState(init_pagination);
	const [total_item, setTotalItem] = useState(100);

	return (
		<PaginationContext.Provider
			value={{
				pagination,
				setPagination,
				total_item,
				setTotalItem,
			}}
		>
			{children}
		</PaginationContext.Provider>
	);
};

export { PaginationContext, PaginationProvider };
