import React, { createContext, useEffect, useState } from "react";

export type alertType = {
	is_show: boolean;
	type: "success" | "info" | "warning" | "error";
	message: string;
};

interface AlertNode {
	alert_detail: alertType;
	setAlertDetail: (alertType: alertType) => void;
}
const AlertContext = createContext({} as AlertNode);

const initial_state: alertType = {
	is_show: false,
	type: "success",
	message: "",
};

interface Props {}
const AlertProvider: React.FC<Props> = ({ children }) => {
	const [alert_detail, setAlertDetail] = useState<alertType>(initial_state);

	const showAlert = () => {
		if (alert_detail.is_show) {
			const timer = setTimeout(() => {
				setAlertDetail({ ...initial_state, is_show: false });
			}, 5000);
			return () => clearTimeout(timer);
		}
	};

	useEffect(showAlert, [alert_detail]);

	useEffect(() => {
		setAlertDetail(initial_state);
		return () => setAlertDetail(initial_state);
	}, []);

	return (
		<AlertContext.Provider value={{ alert_detail, setAlertDetail }}>
			{children}
		</AlertContext.Provider>
	);
};

export { AlertContext, AlertProvider };
