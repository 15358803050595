import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link, useRouteMatch } from "react-router-dom";

import { AuthContext } from "../../../contexts/Auth/AuthContext";
import {
	NotificationContext,
	NotificationProvider,
} from "../../../contexts/Notification/NotificationContext";

import { NotificationOverview } from "../../Notification/NotificationOverview";

import { Icons } from "../../../themes/icons";
import { urlPath } from "../../../utils/constant/urlPath";

import "./style.scss";
import { getToken } from "../../../utils/localStorage";
import {
	accessListsType,
	access_lists_init,
} from "../../../interfaces/access_lists_interfaces";
import routes from "../../../routes";
interface Props {}

export const TopHeader: React.FC<Props> = () => {
	const history = useHistory();
	let { path, params }: any = useRouteMatch();
	const { Logout } = useContext(AuthContext);
	const [is_show, setIsShow] = useState({
		notification: false,
		menu: false,
	});
	const [crumbs, setBreadCrumbs] = useState<any>([]);

	const [access_lists, setAccessLists] =
		useState<accessListsType>(access_lists_init);

	useEffect(() => {
		getToken().then((payload: any) => {
			const token_access_lists = payload.data.access_lists;
			const lists_parsed = JSON.parse(token_access_lists);
			setAccessLists(lists_parsed);
		});
		return () => {};
	}, []);

	useEffect(() => {
		const paths: any = routes
			.filter((route) => path.includes(route.path))
			.map(({ path, ...rest }) => ({
				path: Object.keys(params).length
					? Object.keys(params).reduce(
							(path, param) => path.replace(`:${param}`, params[param]),
							path
					  )
					: path,
				...rest,
			}));
		paths?.shift();
		setBreadCrumbs(paths);
	}, []);

	const NotificationIcon = () => {
		const { unread_count } = useContext(NotificationContext);
		return (
			<img
				className="notification pointer"
				src={
					unread_count > 0
						? Icons.notification_bell_red
						: Icons.notification_icon
				}
				alt="notification"
				onClick={() =>
					setIsShow({
						...is_show,
						notification: !is_show.notification,
						menu: false,
					})
				}
				width="22px"
				height="22px"
			/>
		);
	};

	return (
		<NotificationProvider>
			<div className="global-top-header">
				<div className="global-top-header__title">
					{/* {pathname === "/" ? "Dashboard" : pathname.split("/").join(" ")} */}
					{crumbs?.map(({ name, path }: any, key: any) =>
						key + 1 === crumbs?.length ? (
							<span key={`${name} - ${key}`} className="active-crumbs">
								{name}
							</span>
						) : (
							<>
								<Link
									key={`${name} - ${key}`}
									to={path}
									className="crumbs"
									onClick={() => {
										if (path === urlPath.Reports.Root.path) {
											window.location.replace(urlPath.Reports.Root.path);
										}
									}}
								>
									{name}
								</Link>
								<span className="separator">/</span>
							</>
						)
					)}
				</div>
				<div className="global-top-header__action">
					<NotificationIcon />
					<div className="top-header-menu">
						<img
							className="pointer"
							src={Icons.user_icon_default}
							alt="profile pict"
							width="20px"
							onClick={() => history.push(urlPath.Profile.Root.path)}
						/>
						<div
							className="pointer arrow-container"
							onClick={() =>
								setIsShow({
									...is_show,
									notification: false,
									menu: !is_show.menu,
								})
							}
						>
							<i className="arrow down" />
						</div>
						<NotificationOverview is_show={is_show} setIsShow={setIsShow} />
						<div
							className={`top-header-menu-list box-shadow ${
								is_show.menu ? "show" : "hide"
							}`}
							onMouseLeave={() => setIsShow({ ...is_show, menu: false })}
						>
							<p onClick={() => history.push(urlPath.Profile.Root.path)}>
								Profile
							</p>

							{!access_lists?.ams?.setting?.is_active &&
							!access_lists?.rms?.setting?.is_active ? (
								""
							) : (
								<p
									onClick={() =>
										history.push(
											urlPath.Settings.Root.path +
												"/?tab_path=" +
												urlPath.Settings.TabPath.UserDepartment.path
										)
									}
								>
									{urlPath.Settings.Root.name}
								</p>
							)}
							<p onClick={() => Logout()}>Log Out</p>
						</div>
					</div>
				</div>
			</div>
		</NotificationProvider>
	);
};
