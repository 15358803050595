import React, { useContext } from "react";
import { useHistory } from "react-router";
import dayjs from "dayjs";

import { NotificationContext } from "../../../contexts/Notification/NotificationContext";

import { Icons } from "../../../themes/icons";
import { TablePagination } from "../../Global/TablePagination";

import { urlPath } from "../../../utils/constant/urlPath";

import "./style.scss";

interface Props {
	is_modal?: boolean;
}

export const ListsAndOverview: React.FC<Props> = ({ is_modal }) => {
	const { notification_lists, readNotification } =
		useContext(NotificationContext);
	const history = useHistory();
	const box_number = is_modal ? 3 : undefined;

	return notification_lists[0].id !== "" ? (
		<div className="notification-modal-container">
			{notification_lists?.slice(0, box_number).map((notification: any, i) => {
				return (
					<div
						onClick={() => {
							readNotification(notification.id);
							history.push(
								notification.event_id !== null
									? urlPath.Events.EventDetail.path + notification.event_id
									: urlPath.Workorder.WorkorderDetail.path +
											`/${notification?.workorder_id}`
							);
						}}
						className={`notification-modal box-shadow ${
							is_modal ? "notification-white" : "bg-grey-accent"
						}`}
						key={i}
					>
						<div className="notification-modal__left">
							{/* <p>{notification.description}</p> */}
							<div
								style={{ fontSize: "14x" }}
								dangerouslySetInnerHTML={{ __html: notification.description }}
							/>
							{/* <p className={`${!is_modal && "text-black"}`}>
							Event created by {notification.created_by}
						</p> */}
						</div>
						<div className="notification-modal__right">
							<img
								className={`${notification.is_read ? "invisible" : "visible"}`}
								src={Icons.notification_red}
								alt="notification"
								width="18px"
								height="18px"
							/>
							{!is_modal && (
								<p className="notification-modal__right__text">
									{dayjs(notification.created_at).format("DD MMMM YYYY HH:mm")}
								</p>
							)}
						</div>
					</div>
				);
			})}
			{!is_modal && (
				<div className="notification-pagination-container">
					<TablePagination />
				</div>
			)}
		</div>
	) : (
		<div className={`show-flex centered`}>
			<p>There are no notification yet</p>
		</div>
	);
};
