import React, { createContext, useContext, useEffect, useState } from "react";

import {
	reminderFormData,
	reminder_form_data_init,
	reminderListType,
	reminderType,
	reminder_list_init,
} from "../../interfaces/rms/reminder_interfaces";

import { AlertContext } from "../Alert/AlertContext";
import { PaginationContext } from "../PaginationContext";
import { FirebaseContext } from "../../contexts/FirebaseContext";

import * as UserService from "../../services/general/UserService";
import * as ReminderService from "../../services/reminderService";
import { userType } from "../../interfaces/user_interfaces";

type ReminderPopUpDataType = {
	date_time: Date;
	description: string;
	status: string;
};

interface ReminderNode {
	reminder_lists: reminderListType[];
	setReminderLists: Function;

	reminder_selected_detail: reminderFormData;
	setReminderSelectedDetail: Function;

	form_data: reminderFormData;
	setFormData: Function;

	form_config: formConfigType;
	setFormConfig: Function;

	show_pop_up_lists: boolean;
	setShowPopUpLists: Function;

	view_type: reminderType;
	setViewType: Function;

	addReminder: Function;
	editReminder: Function;

	selected_user: userType[];
	setSelectedUser: Function;

	reminder_popup_data: ReminderPopUpDataType;
	setReminderPopupData: Function;

	temp_status: string;
	setTempStatus: Function;
}

type formConfigType = {
	is_show: boolean;
	form_type: "add" | "edit";
};
const form_config_init: formConfigType = {
	is_show: false,
	form_type: "add",
};

const ReminderContext = createContext({} as ReminderNode);

const ReminderProvider: React.FC = ({ children }) => {
	const { payload, setPayload } = useContext(FirebaseContext);
	const [reminder_popup_data, setReminderPopupData] = useState<any>({});
	const [temp_status, setTempStatus] = useState<string>("");

	const [view_type, setViewType] = useState<reminderType>("recent");
	const { setAlertDetail } = useContext(AlertContext);
	const { pagination } = useContext(PaginationContext);

	const [form_data, setFormData] = useState<reminderFormData>(
		reminder_form_data_init
	);
	const [form_config, setFormConfig] =
		useState<formConfigType>(form_config_init);

	const [selected_user, setSelectedUser] = useState<userType[]>([]);

	const [show_pop_up_lists, setShowPopUpLists] = useState<boolean>(false);
	const [reminder_lists, setReminderLists] = useState<reminderListType[]>([
		reminder_list_init,
	]);
	const [reminder_selected_detail, setReminderSelectedDetail] =
		useState<reminderFormData>(reminder_form_data_init);

	const getReminderLists = () => {
		ReminderService.getReminderLists(pagination, view_type).then((data: any) =>
			setReminderLists(data)
		);
	};
	useEffect(getReminderLists, [pagination, view_type]);

	const getMultipleUser = () => {
		if (form_data?.assign_to && form_data?.assign_to[0] !== "") {
			UserService.getMultipleUser(form_data.assign_to).then((data: any) =>
				setSelectedUser(data)
			);
		}
	};
	useEffect(getMultipleUser, [form_data.assign_to]);

	const addReminder = async () => {
		setFormConfig({ ...form_config, is_show: false });
		try {
			const result: any = await ReminderService.addReminder({
				...form_data,
				assign_to: selected_user.map((data) => data.id),
			});
			setAlertDetail({
				is_show: true,
				type: "success",
				message: result.message,
			});
		} catch (e: any) {
			console.log(e);
			setAlertDetail({
				is_show: true,
				type: "success",
				message: e,
			});
		} finally {
			setFormConfig({ ...form_config, is_show: false });
			setFormData(reminder_form_data_init);
			getReminderLists();
		}
	};

	const editReminder = async () => {
		try {
			const result: any = await ReminderService.editReminder(
				{
					...form_data,
					assign_to: selected_user.map((data) => data.id),
				},
				reminder_selected_detail.id
			);
			setAlertDetail({
				is_show: true,
				type: "success",
				message: result.message,
			});
		} catch (e: any) {
			console.log(e);
			setAlertDetail({
				is_show: true,
				type: "success",
				message: e,
			});
		} finally {
			getReminderLists();
		}
	};

	useEffect(() => {
		if (form_config.form_type === "edit") {
			setFormData(reminder_selected_detail);
			setTempStatus(reminder_selected_detail.data.status);
		} else {
			setFormData(reminder_form_data_init);
		}
	}, [form_config, reminder_selected_detail]);

	useEffect(() => {
		if (
			payload?.data?.topic === "reminder" &&
			payload?.data?.service === "rms"
		) {
			setShowPopUpLists(true);
			setReminderPopupData(payload.data);
		}
		return () => {
			setPayload();
		};
	}, [payload]); // ignore false positive

	return (
		<ReminderContext.Provider
			value={{
				reminder_lists,
				setReminderLists,

				reminder_selected_detail,
				setReminderSelectedDetail,

				form_data,
				setFormData,

				form_config,
				setFormConfig,

				show_pop_up_lists,
				setShowPopUpLists,

				view_type,
				setViewType,

				addReminder,
				editReminder,

				selected_user,
				setSelectedUser,

				reminder_popup_data,
				setReminderPopupData,

				temp_status,
				setTempStatus,
			}}
		>
			{children}
		</ReminderContext.Provider>
	);
};

export { ReminderContext, ReminderProvider };
